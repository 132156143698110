// 将base64转换为文件流
import store from '@/store'
import router from '@/router'

export function getFileSize(file) {
  var fileSize = Math.round(file / 1024)
  var suffix = 'KB'
  if (fileSize > 1000) {
    fileSize = Math.round(fileSize / 1000)
    suffix = 'MB'
  }
  var fileSizeParts = fileSize.toString().split('.')
  fileSize = fileSizeParts[0]
  if (fileSizeParts.length > 1) {
    fileSize += '.' + fileSizeParts[1].substr(0, 2)
  }
  fileSize += suffix
  return fileSize
}
// 将base64转换为文件流
export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(',')
  var mime = arr[0].match(/:(.*?);/)[1]
  var bstr = atob(arr[1])
  var n = bstr.length
  var u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}
// 数组对象交换（吧数组里的某个对象和另一个对象交换）
export function tempArray(arr, index1, index2) {
  arr[index1] = arr.splice(index2, 1, arr[index1])[0]
  console.log(arr)
  return arr
}
export function change(limit) {
  var size = ''
  if (limit < 0.1 * 1024) {
    // 小于0.1KB，则转化成B
    size = limit.toFixed(2) + 'B'
  } else if (limit < 0.1 * 1024 * 1024) {
    // 小于0.1MB，则转化成KB
    size = (limit / 1024).toFixed(2) + 'KB'
  } else if (limit < 0.1 * 1024 * 1024 * 1024) {
    // 小于0.1GB，则转化成MB
    size = (limit / (1024 * 1024)).toFixed(2) + 'MB'
  } else {
    // 其他转化成GB
    size = (limit / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
  }

  var sizeStr = size + '' // 转成字符串
  var index = sizeStr.indexOf('.') // 获取小数点处的索引
  var dou = sizeStr.substr(index + 1, 2) // 获取小数点后两位的值
  if (dou === '00') {
    // 判断后两位是否为00，如果是则删除00
    return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
  }
  return size
}

// 处理get请求，传入参数对象拼接
const formatUrl = obj => {
  const params = Object.values(obj).reduce(
    (a, b, i) => `${a}${Object.keys(obj)[i]}=${b}&`,
    '?'
  )
  return params.substring(0, params.length - 1)
}
// 增删改查本地存储
export function localSet(key, value) {
  if (checkLocalStorage()) {
    window.localStorage.setItem(key, value)
  } else {
    const Days = 30
    const exp = new Date()
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000)
    document.cookie =
        key + '=' + escape(value) + ';expires=' + exp.toGMTString()
  }
}
export function localGet(key) {
  if (checkLocalStorage()) {
    return window.localStorage.getItem(key)
      ? window.localStorage.getItem(key)
      : null
  } else {
    return getCookie(key)
  }
}

function checkLocalStorage() {
  // 确认是否支持Localstorage
  try {
    return !!(window.localStorage &&
        (window.localStorage.setItem('a', 123),
        window.localStorage.getItem('a') === 123))
  } catch (e) {
    return false
  }
}

function getCookie(name) {
  try {
    let arr
    const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
    if ((arr = document.cookie.match(reg))) return unescape(arr[2])
    else return null
  } catch (e) {
    return null
  }
}

function callNewPage(location) {
  var shopifyShop = store.getters['shopify/getShop']
  if (!shopifyShop) {
    router.push(location)
    return
  }
  if (typeof location === 'string') {
    router.push({ path: location, query: { _shop: shopifyShop } })
  } else {
    if (!location.query) {
      location.query = {}
    }
    location.query._shop = shopifyShop
    router.push(location)
  }
}

export async function waitForMs(t) {
  return new Promise(resolve => {
    setTimeout(resolve, t)
  })
}

export async function waitForEle(e) {
  for (;;) {
    if (e()) {
      return
    }
    await waitForMs(100)
  }
}
export function deepClone(obj) { // 简易深拷贝
  const _obj = JSON.stringify(obj)
  const objClone = JSON.parse(_obj)
  return objClone
}

/**
 * Checks if `value` is `null` or `undefined`.
 *
 * @static
 * @memberOf _
 * @since 4.0.0
 * @category Lang
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is nullish, else `false`.
 * @example
 *
 * _.isNil(null);
 * // => true
 *
 * _.isNil(void 0);
 * // => true
 *
 * _.isNil(NaN);
 * // => false
 */
export function isNil(value) {
  return value == null
}

export default {
  formatUrl,
  callNewPage,
  waitForMs,
  waitForEle
}
