<template>
  <router-link :exact="exact" :active-class="activeClass" :to="cto">
    <slot />
  </router-link>
</template>

<script>

export default {
  name: 'Link',
  props: {
    // eslint-disable-next-line vue/require-prop-types
    to: {
      required: true
    },
    activeClass: {
      type: String,
      default: 'router-link-active'
    },
    tag: {
      type: String,
      default: 'a'
    },
    exact: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cto() {
      var shopifyShop = this.$store.getters['shopify/getShop']
      if (!shopifyShop) {
        return this.to
      }
      if (typeof this.to === 'string') {
        return { path: this.to, query: { _shop: shopifyShop }}
      } else {
        var r = this.to
        if (!r.query) {
          r.query = {}
        }
        r.query._shop = shopifyShop
        return r
      }
    }
  }
}
</script>

<style scoped>

</style>
