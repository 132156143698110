// 监听页面大小
const SWITCH_SCREEN = 'SWITCH_SCREEN'
// 设置推广链接的iv
const SET_INVITE_IV = 'SET_INVITE_IV'

const SET_IS_MOBILE = 'SET_IS_MOBILE'

const SSR_COUNT_SET = 'SSR_COUNT_SET'

const SSR_COUNTDOWN = 'SSR_COUNTDOWN'

const SSR_OK = 'SSR_OK'

const SET_CUSTOMIZE_API = 'SET_CUSTOMIZE_API'

const SET_ALI_OSS = 'SET_ALI_OSS'

const app = {
  namespaced: true,
  state: () => ({
    bigScreen: true,
    isMobile: false,
    // 推广链接的iv
    inviteIv: null,
    ssrCount: 0,
    aliOss: null,
    customizeApi: {
      submitOssFileApi: null,
      fontsApi: null,
      fontTypeListApi: null,
      fontLangListApi: null,
      textListApi: null,
      emojisApi: null,
      stsApi: null
    }
  }),
  mutations: {
    [SWITCH_SCREEN]: (state, { status }) => {
      state.bigScreen = status
    },
    [SET_ALI_OSS]: (state, aliOss) => {
      state.aliOss = aliOss
    },
    [SET_CUSTOMIZE_API]: (state, { submitOssFileApi, fontsApi, fontTypeListApi, fontLangListApi, textListApi, emojisApi, stsApi }) => {
      state.customizeApi.submitOssFileApi = submitOssFileApi
      state.customizeApi.fontsApi = fontsApi
      state.customizeApi.fontTypeListApi = fontTypeListApi
      state.customizeApi.fontLangListApi = fontLangListApi
      state.customizeApi.textListApi = textListApi
      state.customizeApi.emojisApi = emojisApi
      state.customizeApi.stsApi = stsApi
    },
    [SET_IS_MOBILE]: (state, ismobile) => {
      state.isMobile = ismobile
    },
    [SET_INVITE_IV]: (state, iv) => {
      state.inviteIv = iv
    },
    [SSR_COUNT_SET]: (state, val) => {
      state.ssrCount = val
    },
    // ssr countdown
    [SSR_COUNTDOWN]: (state) => {
      state.ssrCount -= 1
      if (state.ssrCount <= 0) {
        // 1秒后再通知ssr完成，留点时间给vue渲染数据到页面
        setTimeout(() => {
          document.body.appendChild(document.createElement('ssr-ok'))
        }, 1000)
      }
    },
    [SSR_OK]: (state) => {
      // 1秒后再通知ssr完成，留点时间给vue渲染数据到页面
      setTimeout(() => {
        document.body.appendChild(document.createElement('ssr-ok'))
      }, 1000)
    }
  },
  actions: {
    [SWITCH_SCREEN]({ commit }, { width = 980 } = {}) {
      const domWidth = document.documentElement.clientWidth
      const status = !(domWidth <= width)
      commit(SWITCH_SCREEN, { status })
    },
    [SET_IS_MOBILE]({ commit }, isMobile) {
      commit(SET_IS_MOBILE, isMobile)
    },
    [SET_CUSTOMIZE_API]({ commit }, { submitOssFileApi, fontsApi, fontTypeListApi, fontLangListApi, textListApi, emojisApi, stsApi }) {
      commit(SET_CUSTOMIZE_API, { submitOssFileApi, fontsApi, fontTypeListApi, fontLangListApi, textListApi, emojisApi, stsApi })
    },
    [SET_ALI_OSS]({ commit }, aliOss) {
      commit(SET_ALI_OSS, aliOss)
    },
    [SET_INVITE_IV]({ commit }, iv) {
      commit(SET_INVITE_IV, iv)
    }
  },
  getters: {
    getBigScreen(state) {
      return state.bigScreen
    },
    getInviteIv(state) {
      return state.inviteIv
    },
    getIsMobile(state) {
      return state.isMobile
    },
    getCustomizeApi(state) {
      return state.customizeApi
    },
    submitOssFileApi(state) {
      return state.customizeApi.submitOssFileApi
    },
    fontsApi(state) {
      return state.customizeApi.fontsApi
    },
    fontTypeListApi(state) {
      return state.customizeApi.fontTypeListApi
    },
    fontLangListApi(state) {
      return state.customizeApi.fontLangListApi
    },
    textListApi(state) {
      return state.customizeApi.textListApi
    },
    emojisApi(state) {
      return state.customizeApi.emojisApi
    },
    stsApi(state) {
      return state.customizeApi.stsApi
    },
    aliOss(state) {
      return state.aliOss
    }
  }
}
export default app
