import { apiAxios } from '../utils/request.js'
// 取消connect
export const disconnect = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/shop/disconnect', data, Istoast, Send)
}
// 店铺列表
export const shopList = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/shop/list', data, Istoast, Send)
}

// 重新connect
export const reconnect = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/shop/reconnect', data, Istoast, Send)
}

// 获取shopify店铺connect-跳转链接
export const shopifyUrlGet = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/shop/shopify/connect/url/get',
    data,
    Istoast,
    Send
  )
}

// 获取woocommerce店铺connect-状态获取
export const connectStatus = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/shop/woocommerce/connect/status',
    data,
    Istoast,
    Send
  )
}

// 获取woocommerce店铺connect-跳转链接
export const woocommerceUrlGet = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/shop/woocommerce/connect/url/get',
    data,
    Istoast,
    Send
  )
}
// 删除店铺
export const removeStore = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/shop/remove', data, Istoast, Send)
}
// 设置店铺名称
export const setShopName = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/shop/name/set', data, Istoast, Send)
}

// 切换店铺
export const changeShop = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/shop/change', data, Istoast, Send)
}
