<template>
  <div>
    <a-carousel effect="fade" autoplay>
      <div v-for="(item, index) in bannerList" class="bannerBoxItem" :key="`caroul${index}`">
        <img
          :style="{ height: `${computedHeight}vw` }"
          :src="item.imageUrl"
          class="bannerItem"
          fit="cover"
          @click="onClick(item)"
        >
      </div>
    </a-carousel>
  </div>
</template>

<script>
export default {
  props: ['height', 'bannerList'],
  computed: {
    computedHeight() {
      return this.height / 16
    }
  },
  methods: {
    onClick(item) {
      if (item.jumpUrl) {
        window.open(item.jumpUrl, '_blank')
      }
    }
  }
}
</script>
<style lang="less">
  .bannerBoxItem{
    border-radius: 12px;
    overflow: hidden;
  }
.bannerItem {
  object-fit: cover;
  width: 100%;
}
</style>
