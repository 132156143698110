const layoutConfig = {
  namespaced: true,
  state: () => {
    return {
      width: window.innerWidth,
      resizeObserver: undefined
    }
  },
  mutations: {
    setLayutConfig(state, width) {
      state.width = width
    },
    setResizeObserver(state, resizeObserver) {
      state.resizeObserver = resizeObserver
    }
  },
  getters: {
    computedSpan(state) {
      if (state.width < 576) {
        return 24
      }
      if (state.width >= 576 && state.width < 768) {
        return 12
      }
      if (state.width >= 768 && state.width < 992) {
        return 8
      }
      return 6
    }
  },
  actions: {
    layoutResizeInit({ commit, state }) {
      if (!state.resizeObserver) {
        const resizeObserver = new ResizeObserver(entries => {
          for (const entry of entries) {
            commit('setLayutConfig', entry.contentRect.width)
          }
        })
        resizeObserver.observe(document.body)
      }
    }
  }
}

export default layoutConfig
