const SET_CANVASSiZE = 'SET_CANVASSiZE'
const SET_ZOOM = 'SET_ZOOM'

const app = {
  namespaced: true,
  state: () => ({
    canvasSize: {
      width: 900,
      height: 700
    },
    zoom: 80
  }),
  mutations: {
    [SET_CANVASSiZE]: (state, canvas) => {
      state.canvasSize = canvas
    },
    [SET_ZOOM]: (state, canvas) => {
      state.zoom = canvas
    }
  },
  actions: {
    [SET_CANVASSiZE]({ commit }, iv) {
      commit(SET_CANVASSiZE, iv)
    },
    [SET_ZOOM]({ commit }, iv) {
      commit(SET_ZOOM, iv)
    }
  },
  getters: {
    getCanvasSize(state) {
      return state.canvasSize
    },
    getZoom(state) {
      return state.zoom
    }
  }
}
export default app
