// 设置店铺名称
const SET_SHOP = 'SET_SHOP'

// 设置HOST
const SET_HOST = 'SET_HOST'

// 设置APP
const SET_APP = 'SET_APP'

const app = {
  namespaced: true,
  state: () => ({
    shop: null,
    host: null,
    app: null
  }),
  mutations: {
    [SET_SHOP]: (state, shop) => {
      state.shop = shop
    },
    [SET_HOST]: (state, host) => {
      state.host = host
    },
    [SET_APP]: (state, app) => {
      state.app = app
    }
  },
  actions: {
    [SET_SHOP]({ commit }, shop) {
      commit(SET_SHOP, shop)
    },
    [SET_APP]({ commit }, app) {
      commit(SET_APP, app)
    },
    [SET_HOST]({ commit }, host) {
      commit(SET_HOST, host)
    }
  },
  getters: {
    getHost(state) {
      return state.host
    },
    getShop(state) {
      return state.shop
    },
    getApp(state) {
      return state.app
    }
  }
}
export default app
