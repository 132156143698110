import { apiAxios } from '@/utils/request'

/** *
 * 活动列表
 * @param data
 * @param Istoast
 * @param Send
 * @returns {Promise | Promise<unknown>}
 */
export const dashboardActivityList = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/activity/dashboard/list',
    data,
    Istoast,
    Send
  )
}

/** *
 * 活动详情
 * @param data
 * @param Istoast
 * @param Send
 * @returns {Promise | Promise<unknown>}
 */
export const activityDetail = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/activity/detail', data, Istoast, Send)
}
