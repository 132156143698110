// 枚举使用
export function dictFilter(value, dict, nullValue) {
  nullValue = nullValue || ''
  var cValue = value
  if (!isNaN(cValue)) {
    cValue = parseInt(cValue)
  }
  for (const item in dict) {
    if (dict[item].value === cValue) {
      return dict[item].text
    }
  }
  return nullValue
}
// 保留小数
export function MoneyFormat(value, zeroValue = '0.00') {
  if (typeof value === 'undefined') {
    return '-'
  }
  if (value === null) {
    return '-'
  }
  if (!value) return zeroValue
  return toFixed(parseFloat(value), 2)
}

export function RateFormat(value) {
  if (!value) return '0'
  return (value * 100).toFixed(2)
}
// 时间格式转换
export function timeFilter2(value) {
  const date = new Date(+new Date(value) + 8 * 3600 * 1000)
  const y = date.getFullYear().toString()
  const m = (date.getMonth() + 1).toString()
  const d = date.getDate().toString()
  const h = date.getHours().toString()
  const mi = date.getMinutes().toString()
  const s = date.getSeconds().toString()
  const day = `${y.padStart(2, 0)}-${m.padStart(2, 0)}-${d.padStart(
    2,
    0
  )} ${h.padStart(2, 0)}:${mi.padStart(2, 0)}:${s.padStart(2, 0)}`
  return day
}

export function percentFilter(value) {
  var cValue = value
  if (!isNaN(cValue)) {
    cValue = parseFloat(cValue)
  }
  return toFixed(cValue * 100, 2) + '%'
}

function toFixed(num, s) {
  var t = Math.pow(10, s)
  var des = num * t + 0.5
  des = parseInt(des, 10) / t
  return des + ''
}
