import {
  apiAxios
} from '../utils/request.js'
// 国家列表
export const countrys = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/common/countrys', data, Istoast, Send)
}
// 省份列表
export const provinces = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/common/provinces', data, Istoast, Send)
}
// 物流方式列表
export const logisticsMethods = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/common/logisticsMethods', data, Istoast, Send)
}
// timezone列表
export const timezones = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/common/timezones', data, Istoast, Send)
}
// 全局推送配置信息接
export const pushconfigusing = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/common/globalPushConfig', data, Istoast, Send)
}

export const loginPageImage = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/common/loginPageImage', data, Istoast, Send)
}
// 国家组接口
export const countryGroupsApi = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/common/countryGroups', data, Istoast, Send)
}
