<template>
  <div v-cloak id="app">
    <Header v-if="$route.meta.showHeader" />
    <div id="nav" :class="navClass">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive && isRouterAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive && isRouterAlive" />
<!--      <router-view v-if="!$route.meta.keepAlive && isRouterAlive" />-->
      <!-- dialog -->
      <div v-if="isNewUser && $route.meta.showUser" class="dialog-user">
        <div v-if="newUserStatu == 1" class="d-content">
          <div class="d-left">
            <div class="d-title">Update {{ appName }} Inside out!</div>
            <div class="d-msg">
              Hello my dear friend, really appreciate your continuous use and
              support in the past 3 years. Today, we launched our new updated
              All-In-One Fulfillment Platform for you! Updated UI, better
              products with lower prices, what's more sparking here? Go seek
              them yourself!
            </div>
          </div>
          <div class="d-right">
            <img src="./assets/img/undraw_online_shopping_ga73.svg">
          </div>
          <a-button
            class="d-button"
            size="large"
            type="primary"
            @click="next(1)"
          >Next</a-button>
        </div>
        <div v-if="newUserStatu == 2" class="d-content">
          <div class="c-left">
            <img
              src="./assets/img/undraw_authentication_fsn5.svg"
              width="468"
              height="340"
            >
          </div>
          <div class="c-right">
            <div class="d-title">Create new password</div>
            <div class="con">
              <a-form-model
                ref="ruleForm"
                :model="ruleForm"
                :rules="rules"
                v-bind="layout"
              >
                <a-form-model-item has-feedback prop="email">
                  <a-input
                    v-model="ruleForm.email"
                    disabled
                    size="large"
                    placeholder="Email"
                    autocomplete="off"
                  />
                </a-form-model-item>
                <a-form-model-item has-feedback prop="pass">
                  <a-input
                    v-model="ruleForm.pass"
                    size="large"
                    placeholder="Password"
                    type="password"
                    autocomplete="off"
                  />
                </a-form-model-item>
                <a-form-model-item has-feedback prop="checkPass">
                  <a-input
                    v-model="ruleForm.checkPass"
                    placeholder="Confirm Password"
                    size="large"
                    type="password"
                    autocomplete="off"
                  />
                </a-form-model-item>
                <a-button
                  :loading="iconLoading"
                  type="primary"
                  class="d-button d-button2"
                  @click="submitForm('ruleForm')"
                >Next</a-button>
              </a-form-model>
            </div>
          </div>
        </div>
        <div v-if="newUserStatu == 3" class="d-content">
          <div class="t-left">
            <img src="./assets/img/undraw_online_payments_luau.svg">
          </div>
          <div class="t-right">
            <div class="d-title">Bind new credit card</div>
            <div class="d-msg">
              To provide better service and higher security, we have adopted a
              new Automatic Payment for Fulfillment. The old credit card plan
              won't be available then, Are you willing to rebind the credit
              card?
            </div>
          </div>
          <div class="t-btn">
            <a-button
              type="primary"
              class="t-button"
              @click="next(3)"
            >Bing new</a-button>
            <a-button class="t-button" @click="next(4)">skip</a-button>
          </div>
        </div>
      </div>
      <div v-if="MerchantInfo.emailConfirmStatus == 3" class="dialog-email">
        <div class="warp">
          <a-input
            v-model="verifyEmail"
            placeholder="input placeholder"
            :disabled="isChange"
          />
          <div v-if="verifyEmailError" class="tip">
            Account mailbox already exists
          </div>
          <a-button
            type="primary"
            :loading="iconLoading"
            @click="verify"
          >verify</a-button>
          <p @click="checkEmailChange(0)">Change account email</p>
        </div>
      </div>
      <!-- 新增浏览提示窗口 -->
      <a-modal v-model="limitVisible" :footer="null" width="600px">
        <div class="config-content">
          <p class="config-title">{{ configInfo.title }}</p>
          <p class="config-info" v-html="configInfo.content" />
          <a-button
            type="primary"
            class="config-btn"
            @click="configInfoHandle"
          >Go Now ></a-button>
        </div>
      </a-modal>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { UpdatePwd } from '@/api/user'
import { currentMerchant } from '@/api/merchant'
import { emailChange, confirmSend } from '@/api/user'
import { pushconfigusing } from '@/api/public'
import createApp from '@shopify/app-bridge'
import { Redirect, Button, TitleBar } from '@shopify/app-bridge/actions'
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  data() {
    const validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the Email'))
      }
      callback()
    }
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password'))
      } else {
        if (value.length < 6) {
          callback('Length not less than 6')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password again'))
      } else {
        if (value.length < 6) {
          callback('Length not less than 6')
        } else if (value !== this.ruleForm.pass) {
          callback(new Error("Two inputs don't match!"))
        }
        callback()
      }
    }
    return {
      verifyEmailError: false,
      isChange: true,
      isEmailComfirm: false,
      iconLoading: false,
      isNewUser: false, // 是否新用户
      newUserStatu: 1, // 新用户弹框1 2 3个弹框
      verifyEmail: '',
      ruleForm: {
        pass: '',
        checkPass: '',
        email: ''
      },
      rules: {
        email: [{ validator: validateEmail, trigger: 'change' }],
        pass: [{ validator: validatePass, trigger: 'change' }],
        checkPass: [{ validator: validatePass2, trigger: 'change' }]
      },
      layout: {
        wrapperCol: { span: 22 }
      },
      limitVisible: false,
      configInfo: {},
      intercomEnable: process.env.VUE_APP_INTERCOM_ENABLE === 'true',
      // 路由是否有限，用于重新加载页面
      isRouterAlive: true
    }
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  computed: {
    ...mapState({
      MerchantInfo: state => state.User.merchantInfo
    }),
    ...mapGetters('shopify', {
      shop: 'getShop',
      host: 'getHost',
      app: 'getApp'
    }),
    ...mapGetters('User', {
      appName: 'getAppName'
    }),
    navClass() {
      if (this.$route.meta.showHeader) {
        return 'app-nav pd-top-80'
      } else {
        return 'app-nav'
      }
    }
  },
  watch: {
    MerchantInfo() {
      this.verifyEmail = this.MerchantInfo.username
      if (this.intercomEnable) {
        this.intercomChange()
      }
    }
  },
  async mounted() {
    await this.shopifyConfig()
    this.$store.dispatch('ConfigModule/layoutResizeInit')

    const { data } = await currentMerchant()
    if (data.errorInfo) {
      this.$notification.error({
        message: data.errorInfo
      })
    }
    // 是否是迁移用户
    this.isNewUser = data.refillData
    this.merchantInfoMutations(data)

    this.ruleForm.email = this.MerchantInfo.username
    // eslint-disable-next-line no-empty
    if (this.intercomEnable) {
      this.intercomChange()
    }

    this.pushConfig()
  },
  // 离开当前页面后执行
  destroyed: function() {
    sessionStorage.removeItem('isLimit')
  },

  methods: {
    ...mapMutations('User', [
      'merchantInfoMutations',
      'merchantInfoRefillDataMutations'
    ]),
    async shopifyConfig() {
      if (window.top === window.self) {
        console.log('top is self, do not need config shopify')
        return
      }
      // 从query中获取host和shop，如果有，则设置到vuex中
      var host = this.getQueryString('host')
      var shop = this.getQueryString('shop')
      console.log('host', host)
      console.log('shop', shop)
      if (shop) {
        await this.$store.dispatch('shopify/SET_SHOP', shop)
      }
      if (host) {
        await this.$store.dispatch('shopify/SET_HOST', host)
      }
      const hostStore = this.host
      console.log('hostStore', hostStore)
      if (hostStore) {
        var apiKey = process.env.VUE_APP_SHOPIFY_ID
        console.log(hostStore)
        var app = createApp({
          apiKey: apiKey,
          host: hostStore
        })
        console.log('create app success', hostStore, apiKey)
        await this.$store.dispatch('shopify/SET_APP', app)

        const redirect = Redirect.create(app)
        const openFullSiteButton = Button.create(app, {
          label: 'Open full site'
        })
        const DashboardButton = Button.create(app, { label: 'Dashboard' })
        const CatalogButton = Button.create(app, { label: 'Catalog' })
        const MyProductsButton = Button.create(app, { label: 'My Products' })
        const OrdersButton = Button.create(app, { label: 'Orders' })
        var that = this
        openFullSiteButton.subscribe(Button.Action.CLICK, () => {
          redirect.dispatch(Redirect.Action.REMOTE, {
            url: 'https://' + location.host,
            newContext: true
          })
        })
        DashboardButton.subscribe(Button.Action.CLICK, () => {
          that.callNewPage({
            path: '/'
          })
        })
        CatalogButton.subscribe('click', () => {
          that.callNewPage({
            path: '/catalog'
          })
        })
        MyProductsButton.subscribe('click', () => {
          that.callNewPage({
            path: '/my-product'
          })
        })
        OrdersButton.subscribe('click', () => {
          that.callNewPage({
            path: '/orderlist'
          })
        })
        const titleBarOptions = {
          buttons: {
            secondary: [
              openFullSiteButton,
              DashboardButton,
              CatalogButton,
              MyProductsButton,
              OrdersButton
            ]
          }
        }
        TitleBar.create(app, titleBarOptions)
      }
    },
    getQueryString(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')

      var r = window.location.search.substr(1).match(reg)

      if (r != null) {
        return unescape(r[2])
      }

      return null
    },
    // 检测邮箱是否可以修改
    async checkEmailChange(val) {
      const { code } = await emailChange({ newEmail: this.verifyEmail })
      if (code !== 200) {
        this.verifyEmailError = true
      } else if (val === 1 && code === 200) {
        this.sendEmail()
      }
      this.isChange = false
    },
    async verify() {
      const _this = this
      this.$confirm({
        title: '',
        content: 'A verification email has been sent to your mailbox',
        onOk() {
          return new Promise((resolve, reject) => {
            // 1发送邮件
            _this.checkEmailChange(1)
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000)
          }).catch(() => console.log('Oops errors!'))
        }
      })
    },
    // 发送邮箱
    async sendEmail() {
      this.iconLoading = true
      const { code, msg } = await confirmSend()
      if (code !== 200) {
        this.iconLoading = false
        this.$message.error(msg)
      } else {
        this.iconLoading = false
        this.$message.success('Sent successfully')
      }
    },
    // 新用户弹框
    next(val) {
      if (val === 1) {
        this.newUserStatu = 2
      } else if (val === 3) {
        this.isNewUser = false
        this.callNewPage({
          path: '/account',
          query: {
            type: 1,
            isShowAddCard: true
          }
        })
      } else if (val === 4) {
        this.isNewUser = false
      }
    },
    async submitForm(formName) {
      const _this = this
      this.$refs[formName].validate(valid => {
        if (valid) {
          _this.updatePassword()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async updatePassword() {
      this.iconLoading = true
      const { code, msg } = await UpdatePwd({
        oldPassword: this.ruleForm.pass,
        newPassword: this.ruleForm.checkPass
      })
      if (code === 200) {
        this.$message.success('success')
        this.merchantInfoRefillDataMutations(false)
        this.newUserStatu = 3
      } else {
        this.$message.error(msg)
      }
      this.iconLoading = false
    },
    intercomChange() {
      console.warn('intercomChange')
      console.warn(JSON.stringify(this.MerchantInfo))
      if (!this.MerchantInfo.login) {
        window.Intercom('boot', {
          app_id: process.env.VUE_APP_INTERCOM_APP_ID,
          email: null
        })
      } else {
        var $this = this
        var name = $this.MerchantInfo.fullName
        if (!name) {
          name = $this.MerchantInfo.username
        }
        window.Intercom('boot', {
          app_id: process.env.VUE_APP_INTERCOM_APP_ID,
          email: $this.MerchantInfo.username
          // Website visitor so may not have any user related info
        })
      }
    },
    configInfoHandle() {
      this.limitVisible = false
      window.open(this.configInfo.url, '_blank')
    },
    async pushConfig() {
      const { data } = await pushconfigusing()
      if (data.duration === 2147483647) {
        return
      }
      this.configInfo = data
      const isLimit = JSON.parse(sessionStorage.getItem('isLimit'))
      if (!isLimit) {
        setTimeout(() => {
          this.limitVisible = true
          sessionStorage.setItem('isLimit', true)
        }, data.duration * 1000 * 60)
      }
    },
    reload() {
      this.isRouterAlive = false
      this.$nextTick(async function() {
        this.isRouterAlive = true
      })
    }
  }
}
</script>
<style lang="scss">
@import "./assets/css/common.css";
@import "./assets/css/orderList.css";

.dialog-email {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
  z-index: 30;
  .warp {
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -65px;
    margin-left: -200px;
    width: 450px;
    text-align: center;
    padding-top: 30px;
    .tip {
      font-size: 12px;
      margin-top: 10px;
      color: red;
    }
    p {
      padding-top: 15px;
      cursor: pointer;
    }
    input {
      width: 250px;
    }
    button {
      display: block;
      margin: 0 auto;
      margin-top: 20px;
    }
  }
}
.dialog-user {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
  z-index: 30;
  .d-content {
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -170px;
    margin-left: -450px;
    width: 900px;
    height: 400px;
    display: flex;
    padding: 30px;
    border-radius: 10px;
    .t-left {
      width: 497px;
      height: 300px;
      margin-top: 20px;
      img {
        width: 497px;
        height: 300px;
      }
    }
    .t-right {
      flex: 1;
      margin-left: 40px;
      .d-title {
        margin-top: 20px;
      }
      .d-msg {
        font-size: 14px;
      }
    }
    .t-btn {
      position: absolute;
      bottom: 20px;
      right: 26px;
      .t-button {
        width: 145px;
        height: 40px;
        margin-left: 12px;
      }
    }
    .d-title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 19px;
    }
    .d-left {
      flex: 1;
      height: 340px;
      .d-msg {
        font-size: 14px;
        line-height: 2;
      }
    }
    .d-right {
      width: 390px;
      margin-left: 60px;
      img {
        width: 390px;
        height: 300px;
      }
    }
    .d-button {
      position: absolute;
      bottom: 16px;
      right: 26px;
      width: 160px;
      height: 40px;
    }
    .d-button2 {
      bottom: 40px;
    }
    .c-left {
      width: 468px;
      height: 340px;
    }
    .c-right {
      flex: 1;
      height: 400px;
      margin-left: 60px;
    }
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.pd-top-80 {
  padding-top: 80px;
}

.app-nav {

  // a {
  //   font-weight: bold;
  //   color: #2c3e50;

  //   &.router-link-exact-active {
  //     color: #42b983;
  //   }
  // }
}
.config-content {
  margin: 50px 0;
  padding: 0 30px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #262626;
  line-height: 24px;
}
.config-content .config-title {
  margin-bottom: 35px;
  font-weight: bold;
}
.config-content .config-info {
  text-align: center;
}
.config-content .config-btn {
  width: 240px;
  margin-top: 30px;
}
</style>
