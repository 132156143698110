<template>
  <div class="commodityContainer p15 cursorPointer commontransition" :style="{backgroundColor:bggray?'#f8f8f8':'#ffffff'}">
    <r-link :to="`/product/${product.type}/${product.id}`">

      <div class="positionRe  w-100 portraitbox">
        <div class="positionAb productTagViews">
          <div v-if="product.isNew" class="tagBlue productTagView">
            {{ "New" }}
          </div>
          <div v-if="product.isHot" class="tagYellow productTagView">
            {{ "Hot" }}
          </div>
          <div v-if="product.favorableDiscount > 0" class="tagRed productTagView">
            -{{ product.favorableDiscount | percentFilter }}
          </div>
        </div>

        <images
          v-if="product.suspendImageUrl"
          class="portrait commontransition w-100"
          :url="product.mainShowImageUrl"
          :width="600"
          :height="600"
          fit="cover"
        />
        <images
          v-else
          class="portrait3 commontransition w-100"
          :url="product.mainShowImageUrl"
          :width="600"
          :height="600"
          fit="cover"
        />
        <images
          v-if="product.suspendImageUrl"
          class="portrait2 commontransition w-100"
          :url="product.suspendImageUrl"
          :width="600"
          :height="600"
          fit="cover"
        />
      </div>
      <div class="d-flex a-center modelText mt10 ">
        <div
          :title="product.model"
          class="font16 color333 textEllipsisTwoLine "
        >
          {{ product.model }}
        </div>
      </div>
      <div
        class="colorGray font14 d-flex j-center"
        v-html="product.blankContent"
      />
      <div class="d-flex font20 colorprimary d-flex j-center mt15 priceInfo">
        <span v-if="product.favorablePrice">
          <span class="mr15">${{ product.favorablePrice | MoneyFormat }}</span>
          <s>${{ product.price | MoneyFormat }}</s>
        </span>
        <span v-else>
          <span>${{ product.price | MoneyFormat }}</span>
        </span>
      </div>
    </r-link>

  </div>
</template>

<script>
import images from '@/components/images'
export default {
  components: {
    images
  },
  props: ['product', 'bggray'],
  data() {
    return {}
  }
}
</script>
<style lang="less" scoped>
.commodityContainer {
  box-sizing: border-box;
  border-radius: 10px;
  .modelText{
    height: 48px;
    width: 308px;
    // flex: 1;
  }
  .productTagViews {
    left: 14px;
    top: 14px;
    z-index: 8;
  }
  .productTagView {
    padding: 7px 14px;
    border-radius: 8px;
    height: 32px;
    line-height: 16px;
    font-size: 14px;
    font-family: Segoe UI-Regular, Segoe UI;
    font-weight: 400;
    margin-top: 2px;
  }
  .tagBlue {
    color: #009ef8;
    background-color: #f0f9fe;
  }
  .tagRed {
    color: #f2416c;
    background-color: #fff5f8;
  }
  .tagYellow {
    color: #ffd000;
    background-color: #fff8dd;
  }
  &:hover {
    box-shadow: 0 0 10px 0 rgba(42, 77, 138, 0.2);
    .viewBtn {
      opacity: 1;
      visibility: initial;
    }
    .portrait3 {
      transform: scale(1.05);
    }
    .portrait {
      display: none;
    }
    .portrait2 {
      display: block;
    }
  }
  .portraitbox {
    height: 320px;
    width: 320px;
    border-radius: 8px;
    overflow: hidden;
  }
  .portrait3 {
    height: 320px;
    // width: 320px;
    border-radius: 8px;
    object-fit: cover;
  }
  .portrait {
    height: 320px;
    // width: 320px;
    border-radius: 8px;
    object-fit: cover;
  }
  .portrait2 {
    height: 320px;
    // width: 320px;
    border-radius: 8px;
    object-fit: cover;
    display: none;
  }
  .viewBtn {
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    border-radius: 8px;
    background-color: #009ef7;
    height: 44px;
  }
}
.priceInfo {
  s {
    color: #949494;
  }
}
.commontransition{
  /deep/ img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
