/**
 * 更新 metaInfo 信息
 * @param opts
 */
import operate from './operate'

/** *
 *
 * @param opts
 * {
      title: 'My Example App', // set a title
      meta: [{                 // set meta
        name: 'keyWords',
        content: 'My Example App'
      },{
        name: 'description',
        content: 'description'
      }]
      link: [{                 // set link
        rel: 'asstes',
        href: 'https://assets-cdn.github.com/'
      }]
    }
 */
export default function updateMetaInfo(opts) {
  operate().removeMetaInfo()
  operate().setMetaInfo(opts)
}
