<template>
  <div class="statisticsView ignore_view" v-if="isLogin">
    <div class="d-flex a-center pt40">
      <span class="font20 font-bold" style="font-family: SegoeUI-Bold-, SegoeUI-Bold;">Statistics</span>
      <div class="ml-auto">
        <a-range-picker format="YYYY-MM-DD" @change="onChange">
          <span slot="suffixIcon" />
        </a-range-picker>
      </div>
    </div>

    <div class="pt40 pb40 d-flex a-center statisticsContent">
      <div v-for="(item) in statisticsList" :key="item.itemName" class="statisticsItemView" :style="{backgroundColor: item.bgColor}">
        <div class="itemName">{{ item.itemName }}</div>
        <div class="itemNumber">{{ item.itemNumber }}</div>
        <div class="itemDivider" />
        <div v-show="item.itemOrderName" class="itemOrder font14">{{ item.itemOrderName+"："+item.itemOrder }}</div>
        <a-icon :type="item.itemIcon" class="itemIcon" theme="filled" />
      </div>
    </div>
  </div>
</template>

<script>
import { statisticsIndex } from '@/api/merchant'
import { mapGetters } from 'vuex'

export default {
  name: 'Statistics',

  data() {
    return {
      statisticsList: [
        {
          itemName: 'Total order num',
          itemNumber: 0,
          itemOrderName: 'Fulfilled orders',
          itemOrder: 0,
          bgColor: '#005EF7',
          itemIcon: 'snippets'
        },
        {
          itemName: 'lnProduction order num',
          itemNumber: 0,
          itemOrderName: 'Partially fulfilled orders',
          itemOrder: 0,
          bgColor: '#35B06A',
          itemIcon: 'check-circle'
        },
        {
          itemName: 'Not paid order num',
          itemNumber: 0,
          itemOrderName: '',
          itemOrder: 0,
          bgColor: '#F0A23A',
          itemIcon: 'warning'
        },
        {
          itemName: 'RiskOrderNum',
          itemNumber: 0,
          itemOrderName: '',
          itemOrder: 0,
          bgColor: '#DB3940',
          itemIcon: 'warning'
        }
      ]

    }
  },
  computed: {
    ...mapGetters('User', {
      isLogin: 'getIsLogin'
    })
  },
  mounted() {
    this.getStatistics()
  },
  methods: {
    onChange(date, dateString) {
      this.startDate = dateString[0]
      this.endDate = dateString[1]
      this.getStatistics(dateString[0], dateString[1])
    },
    async getStatistics(startDate, endDate) {
      const { data } = await statisticsIndex({
        startDate: startDate || null,
        endDate: endDate || null
      })
      this.statisticsList[0].itemNumber = data.totalOrderNum || 0
      this.statisticsList[0].itemOrder = data.partiallyFulfillmentOrderNum || 0
      this.statisticsList[1].itemNumber = data.inProductionOrderNum || 0
      this.statisticsList[1].itemOrder = data.fulfillmentOrderNum || 0
      this.statisticsList[2].itemNumber = data.notPaidOrderNum || 0
      this.statisticsList[3].itemNumber = data.riskOrderNum || 0
    }
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/less/theme.less";

.statisticsView{
  width: @pageWidth;
  margin: 0 auto;

  .ant-calendar-picker{
    border: 1px solid #e4e6ef;
    border-radius: 8px;
    /deep/ .ant-calendar-range-picker-input{
      width: 48%;
    }
    /deep/ .ant-calendar-range-picker-separator{
      vertical-align: middle;
    }
    /deep/ .ant-calendar-picker-input.ant-input{
      background-color: #fff !important;
      box-shadow: none !important;
      // &:active{
      //   border: 1px solid #b5b5c3 !important;
      // }
      // &:focus{
      //   border: 1px solid #b5b5c3 !important;
      // }
      // &:hover{
      //   border: 1px solid #b5b5c3 !important;
      // }
    }
  }
  .ant-calendar-picker:focus{
    border: 1px solid #b5b5c3 !important;
  }
  .statisticsContent{
    .statisticsItemView{
      width: 336px;
      height: 211px;
      position: relative;
      padding: 24px;
      border-radius: 10px;
      color: #ffffff;
      font-family: Segoe UI-Regular, Segoe UI;
      margin-right: 19px;
      .itemName{
        font-size: 14px;
      }
      .itemNumber{
        font-size: 40px;
        padding-top: 14px;
        font-weight: bold;
      }
      .itemDivider{
        width: 288px;
        height: 0px;
        opacity: 0.1;
        border: 1px solid #FFFFFF;
        margin-top: 24px;
      }
      .itemOrder{
        padding-top: 24px;
      }

      .itemIcon{
        position: absolute;
        right: 24px;
        top: 24px;
        opacity: 0.5;
        font-size: 60px;
      }
    }
  }
  .statisticsContent > :last-child{
    margin-right: 0px !important;
  }
}

</style>
