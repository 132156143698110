<template>
  <div>
    <warn1
      v-for="(item) in note1List"
      :key="item.id"
      :item="item"
    />
    <warn2
      v-for="(item) in note2List"
      :key="item.id"
      :item="item"
    />
  </div>
</template>
<script>
import { noticeList } from '../api/index'
import noticeTypeEnum from '@/enums/notice/noticeTypeEnum'
import warn1 from './warn1'
import warn2 from './warn2'

export default {
  components: {
    warn1,
    warn2
  },
  data() {
    return {
      note1List: [],
      note2List: []
    }
  },
  async mounted() {
    const { data } = await noticeList()
    for (let i = 0; i < data.itemList.length; i++) {
      if (data.itemList[i].type === noticeTypeEnum.TYPE_1.value) {
        this.note1List.push(data.itemList[i])
      } else if (data.itemList[i].type === noticeTypeEnum.TYPE_2.value) {
        data.itemList[i].isShow = false
        this.note2List.push(data.itemList[i])
      }
    }
  }
}
</script>
