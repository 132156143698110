import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from "vuex-persistedstate";
import User from './module/User.js'
import shopify from './module/shopify'
import app from './module/app.js'
import ConfigModule from './module/ConfigModule.js'
import Canvas from './module/Canvas.js'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    token: ''
  },
  mutations: {
    Signmutations(state, data) {
      state.token = data
    }
  },
  actions: {
  },
  getters: {
  },
  modules: {
    User,
    shopify: shopify,
    ConfigModule,
    app,
    Canvas
  },

  plugins: [
    // createPersistedState({
    //   paths: ["User"]
    // })
  ]
})
