import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/index.vue'
import updateMetaInfo from '../utils/meta-info/index.js'
import store from '../store/index'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'// progress bar style
NProgress.configure({ showSpinner: false })// NProgress Configuration
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      showHeader: true,
      title: `${store.getters['User/getAppName']} App: The Ultimate Solution for Your Print on Demand Business`,
      description: `Boost your eCommerce profits with ${store.getters['User/getAppName']}. Ecommerce platforms like Shopify and WooCommerce are already integrated with ${store.getters['User/getAppName']} App.`
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: false
    }
  },
  {
    path: '/marketing',
    component: () => import('@/layout/index.vue'),
    redirect: '/marketing',
    name: 'marketing',
    children: [
      {
        path: '/marketing/index',
        component: () => import('@/views/vuetify/Index.vue'),
        name: 'Marketing',
        // 是否显示迁移用户弹框
        showUser: true
      }
    ]
  },
  {
    path: '/change2',
    name: 'Change2',
    component: () => import('../layout/index.vue'),
    props: true
  },
  {
    path: '/change',
    name: 'Change',
    component: () => import('../views/product/Change.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true
    }
  },
  {
    path: '/product-detail/:productId',
    name: 'productDetail',
    props: true,
    component: () => import('../views/product/productDetail.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      showHeader: true
    }
  },
  {
    path: '/product/:type/:id',
    name: 'ProductDetail',
    props: true,
    component: () => import('../views/product_detail'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      showHeader: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/account/Login.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: false,
      showHeader: false,
      title: 'Log In to Your Account',
      description: 'Enjoy secure and convenient access to your account anytime, anywhere. With just a few clicks, you can log in and manage your account.'
    }
  },
  // 发送邮箱后重置密码
  {
    path: '/password-reset',
    name: 'passwordReset',
    component: () => import('../views/account/passwordReset.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: false,
      showHeader: false
    }
  },
  {
    path: '/resetPwd',
    name: 'ResetPwd',
    component: () => import('../views/account/ResetPwd.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: false,
      showHeader: false
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/account/Register.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: false,
      showHeader: false,
      title: 'Sign Up for Free',
      description: 'Register for free to take advantage of our services. Receive special offers and free design tools. Sign up now to explore the endless possibilities.'
    }
  },
  {
    path: '/catalog',
    name: 'Catalog',
    component: () => import('../views/product/catelog_new.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      showHeader: true,
      keepAlive: true,
      title: '400+ POD Products: Find Your Perfect Match Here',
      description: 'Discover a wide range of high-quality products in our collection of 400+ items. From clothing to fashion accessories.'
    }
  },
  {
    path: '/custom-gift',
    name: 'Custom Gift',
    component: () => import('../views/diy/index.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      showHeader: true,
      keepAlive: true,
      title: 'Find the Perfect Gift: 100+ Options',
      description: 'Browse our selection of over 100 gift ideas to find the ideal present for any occasion. Involve your customers in the product design process.'
    }
  },
  {
    path: '/diy_design/:productId',
    name: 'DiyDesign',
    props: true,
    component: () => import('../views/diy/design/index.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      showHeader: true,
      login: true
    }
  },
  {
    path: '/my-product',
    name: 'MyProduct',
    component: () => import('../views/my_products/index.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      showHeader: true,
      login: true,
      title: 'Keep All of Your Product Designs',
      description: 'All of your product designs, including print-on-demand products and gifts synced to the store, will be saved here.'
    }
  },
  {
    path: '/upload_product/:blankProductId',
    name: 'UploadProduct',
    props: true,
    component: () => import('../views/my_products/upload_product.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      showHeader: true,
      login: true
    }
  },
  {
    path: '/etsy/install',
    name: 'EtsyInstall',
    props: true,
    component: () => import('../views/account/etsyInstall.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      showHeader: true,
      login: true
    }
  },
  {
    path: '/etsy/installs',
    name: 'EtsyInstalls',
    props: true,
    component: () => import('../views/account/etsyInstall.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      showHeader: true,
      login: true
    }
  },
  {
    path: '/upload_product_edit/:id',
    props: true,
    name: 'UploadProductEdit',
    component: () => import('../views/my_products/upload_product_edit.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      showHeader: false,
      login: true
    }
  },
  {
    path: '/activity/:handle',
    props: true,
    name: 'Activity',
    component: () => import('../views/activity_detail/index.vue'),
    meta: {
      showUser: true,
      showHeader: true
    }
  },
  {
    path: '/unpay',
    name: 'UnPay',
    component: () => import('../views/order/UnPay.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      login: true,
      showHeader: true
    }
  },
  {
    path: '/orderlist',
    name: 'OrderList',
    component: () => import('../views/order/List.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      login: true,
      showHeader: true,
      title: 'Track Your Orders: Stay Up-to-Date',
      description: 'Keep track of your orders and delivery status with ease. With our user-friendly platform, you can stay up-to-date on all your purchases.'
    }
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('../views/cart/index.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      login: true,
      showHeader: true
    }
  },
  {
    path: '/cart/confirm_order/:ids',
    props: true,
    name: 'CartConfirmOrder',
    component: () => import('../views/cart/confirm_order.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      login: true,
      showHeader: true
    }
  },
  {
    path: '/custom',
    name: 'Custom',
    component: () => import('../views/product/Custom.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      login: true,
      showHeader: false
    }
  },
  {
    path: '/Trending',
    name: 'Trending',
    component: () => import('../views/sds_diy/index.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      login: true,
      showHeader: false
    }
  },
  {
    path: '/diyCustom',
    name: 'DiyCustom',
    component: () => import('../views/custom_diy/index.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      login: true,
      showHeader: false
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/account/Account.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      login: true,
      showHeader: true,
      title: 'Manage Your Account Information with Ease.',
      description: 'Take command of your account with our simple platform. Change your address and contact information with ease.'
    }
  },
  {
    path: '/mockups',
    name: 'Mockups',
    component: () => import('../views/product/Mockups.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      login: true,
      showHeader: true
    }
  },
  {
    path: '/active-email',
    name: 'active-email',
    component: () => import('../views/activeEmail.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      showHeader: true
    }
  },
  {
    path: '/store-name',
    name: 'store-name',
    component: () => import('../views/account/storeName.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      login: true,
      showHeader: true
    }
  },
  {
    path: '/changeShop',
    name: 'changeShop',
    component: () => import('../views/account/changeShop.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      login: true,
      showHeader: false
    }
  },
  {
    path: '/connentShop',
    name: 'connentShop',
    component: () => import('../views/account/connentShop.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      login: true,
      showHeader: false
    }
  },
  {
    path: '/wooShopConReturn',
    name: 'wooShopConReturn',
    component: () => import('../views/account/wooShopConReturn.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      showHeader: false
    }
  },
  {
    path: '/channel',
    name: 'channel',
    component: () => import('../views/channel.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      showHeader: false
    }
  },
  {
    path: '/copyProduct',
    name: 'copyProduct',
    component: () => import('../views/account/copyProduct.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      login: true,
      showHeader: false
    }
  },
  {
    path: '/copyOnceProduct',
    name: 'copyOnceProduct',
    component: () => import('../views/account/copyOnceProduct.vue'),
    meta: {
      // 是否显示迁移用户弹框
      showUser: true,
      login: true,
      showHeader: false
    }
  },
  {
    path: '/jetprint-fulfillment',
    redirect: '/'
  },
  {
    path: '*',
    redirect: '/404' // 重定向
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    // return 期望滚动到哪个的位置
    return { x: 0, y: 0 }
  }
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

router.beforeEach(async(to, from, next) => {
  if (to.meta.login) {
    const isInitMerchantInfo = store.getters['User/getIsInitMerchantInfo']
    const login = store.getters['User/getIsLogin']
    if (isInitMerchantInfo && !login) {
      next({ path: '/login', query: { redirect: to.path } })
      console.log('需要登录')
      return
    }
  } else {
    // vue.$loginDialog()
  }
  NProgress.start()
  next()
})

router.afterEach((to, from) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  var appName = store.getters['User/getAppName']
  updateMetaInfo({
    title: to.meta.title || `${appName} Fulfillment`,
    meta: [
      {
        name: 'keywords',
        content: to.meta.keywords || ''
      },
      {
        name: 'description',
        content: to.meta.description || ''
      }
    ]
  })
  NProgress.done()
})
export default router
