/**
 * 为 dom 设置属性
 * @param el
 * @param opt
 */
import { VUE_META_ATTRIBUTE } from './constants'

// eslint-disable-next-line no-underscore-dangle
export default function _setAttr(el, opt) {
  el.setAttribute(VUE_META_ATTRIBUTE, true)
  // eslint-disable-next-line no-restricted-syntax
  for (const key in opt) {
    // eslint-disable-next-line no-prototype-builtins
    if (opt.hasOwnProperty(key)) {
      el.setAttribute(key, opt[key])
    }
  }
}
