<template>
  <div class="login-right">
    <img style="width: 100%" :src="image">
  </div>
</template>

<script>
import { loginPageImage } from '@/api/public'
export default {
  name: 'CommonRight',
  data() {
    return {
      image: require('../assets/img/order_routing.svg')
    }
  },
  computed: {
    styles() {
      var r = {}
      if (this.image) {
        r.background = `url(${this.image})`
      } else {
        r.background = `url(${require('../assets/img/order_routing.svg')})`
      }
      return r
    }
  },
  mounted() {
    this.getImage()
  },
  methods: {
    getImage() {
      loginPageImage({}, false, false).then(response => {
        if (response.data) {
          this.image = response.data || null
        }
      })
    }
  }
}
</script>

<style scoped>
@import "../assets/css/user.css";
</style>
