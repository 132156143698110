<template>
  <div>
    <div class="homeContainer ">
      <div class="contentWidth userSelectNone ignore_view">
        <Warn class="mt15" />
      </div>
      <div class="contentWidth ignore_view">
        <!-- 轮播图开始 -->
        <div class="mt25 userSelectNone">
          <my-carousel :banner-list="bannerList" height="400" />
        </div>
        <!-- 轮播图结束 -->
      </div>
      <statistics />
      <!-- 商品列表开始 -->
      <div
        v-for="item in activityList"
        :key="item.title"
        :class="`pb40 ${!item.bgColor?'bgwhite':''}`"
      >
        <ActivityInfo :data="item" />
      </div>

      <!-- 商品列表结束 -->
      <!-- 首页弹框开始 -->
      <NoticeModal ref="noticeModal" :width="780" :notice-list="noticeList" />
      <!-- 首页弹框结束 -->
    </div>
  </div>
</template>
<script>
// import Tips from "./components/tips";
import MyCarousel from '@/components/MyCarousel'
import ActivityInfo from './components/activity_info'
import NoticeModal from './components/notice_modal'
import { dashboardActivityList } from '@/api/activity'
import { advertiseList } from '@/api/adve'
import { noticeList } from '@/api/index'
import Warn from '@/components/Warn'
import statistics from './components/statistics'
import { mapGetters, mapMutations } from 'vuex'
import {localGet, localSet} from '@/utils/util'
export default {
  name: 'Home',
  components: {
    // Tips,
    MyCarousel,
    ActivityInfo,
    NoticeModal,
    Warn,
    statistics
  },
  data() {
    return {
      errorIcon: require('../../assets/images/Home/error.svg'), // 错误图标
      visible: true, // 提示是否显示
      modalVisible: true, // 弹框是否显示
      bannerList: [],
      activityList: [],
      noticeList: [],
      dashboardNoticeInterval: null
    }
  },
  computed: {
    ...mapGetters('User', {
      shouldDashboardNotice: 'getShouldDashboardNotice'
    })
  },
  mounted() {
    this.ssrCountSet(3)
    this.getActivityList().finally(() => {
      this.ssrCountDown()
    })
    this.getAdList().finally(() => {
      this.ssrCountDown()
    })
    this.getNoticeList().finally(() => {
      this.ssrCountDown()
    })
  },
  methods: {
    ...mapMutations('User', [
      'shouldDashboardNoticeMutations'
    ]),
    async getActivityList() {
      var response = await dashboardActivityList()
      if (response.code === 200) {
        this.activityList = (response.data.itemList || []).map((item, index) => (Object.assign(item, {
          bgColor: !(index % 2)
        })))
      }
    },
    async getAdList() {
      var response = await advertiseList({ advertiseType: 4 })
      if (response.code === 200) {
        this.bannerList = response.data.itemList || []
      }
    },
    async getNoticeList() {
      var response = await noticeList({ type: 3 })
      if (response.code === 200) {
        this.noticeList = response.data.itemList || []
        this.dashboardNoticeInterval = response.data.dashboardNoticeInterval
        if (this.noticeList && this.noticeList.length > 0) {
          this.showNotice()
        }
      }
    },
    showNotice() {
      if (!this.shouldDashboardNotice) {
        return
      }
      var interval = this.dashboardNoticeInterval || 60
      this.shouldDashboardNoticeMutations(false)
      var dashboardNoticeBeforeTimeT = localGet('dashboardNoticeBeforeTime')
      if (dashboardNoticeBeforeTimeT) {
        const dashboardNoticeBeforeTime = JSON.parse(
          dashboardNoticeBeforeTimeT
        )
        console.log(
          dashboardNoticeBeforeTime,
          new Date().getTime(),
          dashboardNoticeBeforeTime - new Date().getTime(),
          (dashboardNoticeBeforeTime - new Date().getTime()) / 60 / 1000
        )
        if (dashboardNoticeBeforeTime) {
          if (dashboardNoticeBeforeTime > new Date().getTime()) {
            return
          }
        }
      }

      this.$refs.noticeModal.show()
      localSet(
        'dashboardNoticeBeforeTime',
        new Date().getTime() + interval * 1000 * 60
      )
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/less/theme.less";
.contentWidth {
  width: @pageWidth;
  margin: 0 auto;
}
</style>
