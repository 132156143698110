import { localGet,localSet } from '@/utils/util'
const config = {
  namespaced: true,
  state: {
    merchantInfo: {},
    loginMsg: {},
    token: localGet('app-token') || '',
    isPullProduct: false,
    appName: process.env.VUE_APP_APP_NAME,
    mainPage: process.env.VUE_APP_MAIN_PAGE,
    paypalEnable: process.env.VUE_APP_PAYPAL_ENABLE === 'true',
    stripeEnable: process.env.VUE_APP_STRIPE_ENABLE === 'true',
    isPushProduct: 0, // 是否推送产品0不推送1推送
    shouldDashboardNotice: true // 是否需要弹出首页通知
  },
  mutations: {
    IsPushProductmutations(state, data) {
      state.isPushProduct = data
    },
    Tokenmutations(state, data) {
      state.token = data
      localSet('app-token', data)
    },
    // 是否刷新my Products
    IsPullProductMutations(state, data) {
      state.isPullProduct = data
    },
    // 登录信息
    loginMsgmutations(state, data) {
      state.loginMsg = data
    },
    // 商户信息
    merchantInfoMutations(state, data) {
      state.merchantInfo = data
    },
    // 店铺切换
    merchantShopMutations(state, data) {
      state.merchantInfo.currentShop = data
    },
    merchantInfoRefillDataMutations(state, data) {
      state.merchantInfo.refillData = data
    },
    shouldDashboardNoticeMutations(state, data) {
      state.shouldDashboardNotice = data
    }
  },
  getters: {
    getToken(state) {
      return state.token
    },
    getPaypalEnable(state) {
      return state.paypalEnable
    },
    getStripeEnable(state) {
      return state.stripeEnable
    },
    getAppName(state) {
      return state.appName
    },
    getMainPage(state) {
      return state.mainPage
    },
    getIsLogin(state) {
      return state.merchantInfo.login
    },
    getIsInitMerchantInfo(state) {
      if (Object.keys(state.merchantInfo).length > 0) {
        return true
      }
      return false
    },
    getShouldDashboardNotice(state) {
      return state.shouldDashboardNotice
    }
  },
  actions: {
    async setToken({ commit }, token) {
      commit('Tokenmutations', token)
    }
  }
}
export default config
