import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vue-fabric/dist/vue-fabric.min.css'
import Http from './utils/util.js'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import infiniteScroll from 'vue-infinite-scroll'
import './assets/font/font.scss'
import logo from '@/components/logo'
import CommonRight from '@/components/common_right'
import RLink from '@/components/r_link'
import moment from 'moment'
import updateMetaInfo from './utils/meta-info/index.js'
import util from './utils/util'

import 'vuetify/dist/vuetify.min.css'
import vuetify from '../plugins/vuetify' //

import VueBus from '@/utils/eventBus.js'

Vue.use(VueBus)
Vue.use(Antd)
Vue.prototype.$moment = moment // 赋值使用
Vue.prototype.Http = Http

Vue.config.productionTip = false

import * as filters from './filters' // global filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

import './assets/less/antd.less'
import './assets/less/index.less'

Vue.use(infiniteScroll)

Vue.config.productionTip = false

Vue.prototype.callNewPage = util.callNewPage

Vue.component('logo', logo)
Vue.component('RLink', RLink)
Vue.component('CommonRight', CommonRight)
Vue.prototype.updateMetaInfo = updateMetaInfo

Vue.prototype.ssrOk = function() {
  store.commit('app/SSR_OK')
}

Vue.prototype.ssrCountSet = function(num) {
  store.commit('app/SSR_COUNT_SET', num)
}

Vue.prototype.ssrCountDown = function() {
  store.commit('app/SSR_COUNTDOWN')
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
