<template>
  <div class="warn2Container mb15">
    <a-collapse v-if="collapseTipVisible" v-model="collapseTipActiveKey" :bordered="false" :show-arrow="false">
      <a-collapse-panel key="1" class="warn2" :style="'background-color:' + item.componentColor" :show-arrow="false">
        <span slot="header" class="header d-flex a-center pt10 pb10">
          <img
            class="icon mr20"
            :src="!item.iconUrl
              ? require('../assets/img/alert-white.svg')
              : item.iconUrl"
          >
          <span class="warn2title mr5" :style="'color:' + item.fontColor" v-html="item.title" />
          <a-icon type="caret-right" class="font16" :style="{transition: 'all 0.5s ease 0s',transform: collapseTipActiveKey.length ? 'rotate(90deg)' : 'rotate(0deg)'}" />
          <a-icon type="close" class="font24 cursorPointer commontransition primaryHover ml-auto" @click.stop="collapseTipActiveKey=[];collapseTipVisible=false;" />
        </span>
        <p
          :style="'color:' + item.fontColor"
          v-html="item.moreInfo"
        />
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
export default {
  name: 'Warn2',
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      collapseTipVisible: true,
      collapseTipActiveKey: ['1']
    }
  },
  methods: {
    tranformTitle(title) {
      const reg = /<\/?.+?\/?>/g
      return title.replace(reg, '')
    }
  }
}
</script>

<style lang="less" scoped>
.warn2Container{
  border-radius: 12px;
  overflow: hidden;

}
.header{
  padding-left: 8px;
}
.warn2 {
  border-radius: 0.75vw;
  margin-bottom: 0px;
  border: 0;
  overflow: hidden;
}

.icon {
  width: 36px;
  height: 36px;
  object-fit: cover;
}

/deep/ .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box{
  padding-left: 70px !important;
  padding-bottom: 0.25vw;
}

// /deep/ .ant-collapse-header > span {
//   padding-top: 10px;
// }

.ant-collapse-borderless{
  background-color: #fff;
  border-radius: 12px;
}
</style>
