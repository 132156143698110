<template>
  <div class="CommodityListContainer">
    <div class="contentWidth ignore_view">
      <div class="d-flex j-center a-center header positionRe mb40">
        <span class="font20 fontBold ffamily-ss">{{ data.title }}</span>
        <r-link
          :to="`/activity/${data.handle}`"
          :class="
            `d-flex j-center userSelectNone a-center commontransition plr15 cursorPointer positionAb moreBtn ${
              !data.bgColor ? 'scondtype' : ''
            }`
          "
        >
          View more&#x3000;
          <a-icon type="right" class="font15" />
        </r-link>
      </div>
      <a-carousel effect="fade" v-if="data.images" autoplay>
        <div v-for="(item, index) in data.images" class="bannerItem" :key="`caroul${index}`">
          <img
            class="poster"
            :src="item.imageUrl"
            fit="cover"
            @click="onBannerClick(item)"
          >
        </div>
      </a-carousel>
      <!--      <img v-if="data.poster" class="poster mt40" :src="data.poster" fit="cover" />-->
      <div class="mt40">
        <a-row :gutter="20">
          <a-col
            v-for="item in data.products"
            :key="item.id"
            :span="computedSpan"
          >
            <div class="mb20" >
              <transition name="el-fade-in-linear">
                <product-item :product="item" :bggray="!data.bgColor"/>
              </transition>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductItem from '../../../components/product_item'
export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters('ConfigModule', ['computedSpan'])
  },
  components: {
    ProductItem
  },
  props: ['data'],
  methods: {
    onBannerClick(item) {
      if (item.jumpUrl) {
        window.open(item.jumpUrl, '_blank')
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../assets/less/theme.less";

.CommodityListContainer {
  padding-top: 40px;
  .bannerItem{
    overflow: hidden;
    height: 400px;
    border-radius: 12px;
  }

  .contentWidth {
    width: @pageWidth;
    margin: 0 auto;
  }
  .poster {
    height: 400px;
    object-fit: cover;
    width: 100%;
  }
  .header {
    height: 40px;
  }
  .moreBtn {
    height: 40px;
    border-radius: 8px;
    background-color: #fff;
    color: #4cbbf9;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    &:hover {
      color: #fff;
      background-color: #4cbbf9;
    }
    &.scondtype {
      color: #6b6e88;
      background-color: #f3f6f9;
      &:hover {
        color: #fff;
        background-color: #4cbbf9;
      }
    }
  }
}
</style>
