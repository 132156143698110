import axios from 'axios'
import qs from 'qs'
import Router from '../router/index.js'
import store from '../store'
import { getSessionToken } from '@shopify/app-bridge-utils'
import util from './util'

axios.defaults.timeout = 100000 // 超时时间
axios.defaults.baseURL = process.env.VUE_APP_API_URL

function getTimeZone() {
  var i = new Date().getTimezoneOffset() / 60
  i = -i
  if (i > 0) {
    return '+' + i
  } else if (i === 0) {
    return ''
  } else {
    return i
  }
}

// 在config.js文件中统一存放一些公共常量，方便之后维护

// let Token;
// 添加请求拦截器，在发送请求之前做些什么(**具体查看axios文档**)--------------------------------------------
axios.interceptors.request.use(
  async function(config) {
    if (!config.headers['Content-Type']) {
      if (
        config.url === '/shopapi/custom/product/generate/v2' ||
        config.url === '/shopapi/custom/product/modify' ||
        config.url === '/shopapi/custom/product/generatePreview/v2' ||
        config.url === '/shopapi/order/price/calculate' ||
        config.url === '/shopapi/custom/product/remove/batch' ||
        config.url === '/shopapi/pay/paypal/create' ||
        config.url === '/shopapi/pay/stripe/chargeOffSession' ||
        config.url === '/shopapi/order/create' ||
        config.url === '/shopapi/pay/stripe/createPaymentIntent' ||
        config.url === '/shopapi/custom/product/copy/batch' ||
        config.url === '/shopapi/custom/product/preview/save' ||
        config.url === '/shopapi/custom/product/export' ||
        config.url === '/shopapi/custom/product/realtimePreview' ||
        config.url === '/shopapi/custom/product/cutImage' ||
        config.url === '/shopapi/custom/product/sku/modify' ||
        config.url === '/shopapi/custom/product/sds/add' ||
        config.url === '/shopapi/file/updateFileInfo2' ||
        config.url === '/shopapi/file/submitOssFile2' ||
          config.url === '/shopapi/custom/product/realtimePreview/v2' ||
          config.url === '/shopapi/custom/product/generatePreview/v3' ||
          config.url === '/shopapi/custom/product/remove/batch' ||
          config.url === '/shopapi/custom/product/generate/v3'
      ) {
        config.headers['Content-Type'] = 'application/json;charset=UTF-8'
      } else {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      }
    }

    config.headers['m-time-zone'] = getTimeZone()

    const token = store.getters['User/getToken']
    if (token) {
      config.headers['shopapi-token'] = token
    }
    // 嵌入shopify时候才使用
    var shopifyShop = store.getters['shopify/getShop']
    if (shopifyShop) {
      config.headers['shopify-shop'] = shopifyShop
    }
    var app = store.getters['shopify/getApp']
    if (app) {
      config.headers['shopify-session-token'] = await getSessionToken(app)
    }
    return config
  },
  function(error) {
    // 请求错误时弹框提示，或做些其他事

    return Promise.reject(error)
  }
)

// 添加响应拦截器(**具体查看axios文档**)----------------------------------------------------------------
axios.interceptors.response.use(
  function(response) {
    if (response.headers['shopapi-token']) {
      store.dispatch('User/setToken', response.headers['shopapi-token'])
    }
    // 对响应数据做点什么，允许在数据返回客户端前，修改响应的数据
    // 如果只需要返回体中数据，则如下，如果需要全部，则 return response 即可

    return response.data
  },
  function(error) {
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)

// 封装数据返回失败提示函数---------------------------------------------------------------------------
function errorState(response) {
  // 隐藏loading
  // 如果http状态码正常，则直接返回数据
  if (
    response &&
    (response.status === 200 ||
      response.status === 304 ||
      response.status === 400)
  ) {
    // 如果不需要除了data之外的数据，可以直接 return response.data
    return response
  }
}

// 封装数据返回成功提示函数---------------------------------------------------------------------------
function successState(res) {
  // 隐藏loading
  if (res.errno === -1) {
    alert('success')
    return res
  }

  if (res.code === 600) {
    // Router.push({ path: "/login" });
    util.callNewPage({
      path: `/login`
    })
    return
  }
}
// 封装axios--------------------------------------------------------------------------------------
export const apiAxios = (
  method,
  url,
  params = {},
  Istoast = false,
  Send = false,
  iscode = false,
  headers = {},
  isJson = false
) => {
  // let loadingInstance = "";
  const state = store.state.User
  if (state.token || state.token) {
    params['token'] = state.token || state.token || '1'
  }

  if (Send) {
    // const options = {
    //     text: "拼命加载中..."
    // }
    // loadingInstance = Loading.service(options);
  }
  let httpDefault = {}
  if (
    url === '/shopapi/custom/product/generate/v2' ||
    url === '/shopapi/custom/product/modify' ||
    url === '/shopapi/custom/product/generatePreview/v2' ||
    url === '/shopapi/custom/product/remove/batch' ||
    url === '/shopapi/order/price/calculate' ||
    url === '/shopapi/pay/paypal/create' ||
    url === '/shopapi/pay/stripe/chargeOffSession' ||
    url === '/shopapi/order/create' ||
    url === '/shopapi/pay/stripe/createPaymentIntent' ||
    url === '/shopapi/custom/product/copy/batch' ||
    url === '/shopapi/custom/product/preview/save' ||
    url === '/shopapi/custom/product/export' ||
    url === '/shopapi/custom/product/realtimePreview' ||
    url === '/shopapi/custom/product/cutImage' ||
    url === '/shopapi/custom/product/sku/modify' ||
    url === '/shopapi/custom/product/sds/add' ||
    url === '/shopapi/file/updateFileInfo2' ||
    url === '/shopapi/file/submitOssFile2' ||
      url === '/shopapi/custom/product/realtimePreview/v2' ||
      url === '/shopapi/custom/product/generatePreview/v3' ||
      url === '/shopapi/custom/product/generate/v3' ||
    isJson
  ) {
    httpDefault = {
      method: method,
      url: url,
      headers: headers,
      // withCredentials: true,
      // `params` 是即将与请求一起发送的 URL 参数
      // `data` 是作为请求主体被发送的数据

      data: params
    }
  } else {
    httpDefault = {
      method: method,
      url: url,
      headers: headers,
      // withCredentials: true,
      // `params` 是即将与请求一起发送的 URL 参数
      // `data` 是作为请求主体被发送的数据

      data: qs.stringify(params)
    }
  }

  // 注意**Promise**使用(Promise首字母大写)
  return new Promise((resolve, reject) => {
    axios(httpDefault)
      // 此处的.then属于axios
      .then(res => {
        if (Istoast) {
          if (iscode) {
            if (res.code !== 0) {
              // Toast(res.msg)
            }
          } else {
            // Toast(res.msg)
          }
        } else {
          if (Send) {
            // this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
            //     loadingInstance.close();
            // });
          }
        }
        successState(res)
        if (res.code === 4005) {
          Router.push({ path: '/sign' })
        }
        resolve(res)
      })
      .catch(response => {
        // Toast('网络请求失败，请稍后再试一下')
        errorState(response)
        reject(response)
      })
  })
}
// form-data 图片上传
export const upAxios = (params = {}) => {
  // Toast.loading({
  //     overlay: true,
  //     duration: 0,
  //     message: "上传中...",
  //     forbidClick: true,
  //     loadingType: "spinner"
  // });
  const param = new FormData() // 创建form对象
  params['access_token'] = ''
  const state = store.state.User

  if (state.token || state.Sign.token) {
    params['access_token'] = state.token || state.Sign.token || '1'
  }
  // if (store.state.User.Key) {
  //     params['access_token'] = store.state.User.Key || ''

  // }
  param.append('type', params.type)
  param.append('token', params.access_token)
  param.append('file', params.file) // 通过append向form对象添加数据
  const httpDefault = {
    method: 'post',
    url: params.url,
    data: param
  }

  // 注意**Promise**使用(Promise首字母大写)
  return new Promise((resolve, reject) => {
    axios(httpDefault)
      // 此处的.then属于axios
      .then(res => {
        successState(res)
        resolve(res)
        // Toast(res.msg)
      })
      .catch(response => {
        // Toast('网络请求失败，请稍后再试一下')
        errorState(response)
        reject(response)
      })
  })
}
// 输出函数getAxios、postAxios、putAxios、delectAxios，供其他文件调用-----------------------------
// Vue.js的插件应当有一个公开方法 install。这个方法的第一个参数是 Vue 构造器，第二个参数是一个可选的选项对象。
