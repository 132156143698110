import { apiAxios } from '../utils/request.js'
// 白板类型
export const typeList = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/blankProduct/type/list',
    data,
    Istoast,
    Send
  )
}

// 白板类型 Tree
export const typeTree = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/blankProduct/type/tree',
    data,
    Istoast,
    Send
  )
}

// 白板列表（分页）
export const blankProductPage = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/blankProduct/page', data, Istoast, Send)
}
// 白板详情
export const productDetail = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/blankProduct/detail', data, Istoast, Send)
}
// 提交刀版和sku，生成未发布的定制产品，进行下一步定制(注意，请求格式是application/json)
export const productGenerate = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/generate/v2',
    data,
    Istoast,
    Send
  )
}
export const productGenerateV3 = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/generate/v3',
    data,
    Istoast,
    Send
  )
}
// 发布产品,返回发布进度ID
export const generateStatus = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/generateStatus',
    data,
    Istoast,
    Send
  )
}
// 定制产品详情
export const generateDetail = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/detail',
    data,
    Istoast,
    Send
  )
}
// 产品收藏夹
export const customCollectionList = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/customCollectionList',
    data,
    Istoast,
    Send
  )
}

export const serverLogisticsMethods = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/serverLogisticsMethods',
    data,
    Istoast,
    Send
  )
}
// 修改定制产品
export const productModify = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/modify',
    data,
    Istoast,
    Send
  )
}
// 发布产品，返回发布进度ID
export const publish = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/publish',
    data,
    Istoast,
    Send
  )
}
// 获取发布进度
export const publishStatus = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/publishStatus',
    data,
    Istoast,
    Send
  )
}

// 保存预览图片
export const previewSave = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/preview/save',
    data,
    Istoast,
    Send
  )
}
// 生成预览图片
export const generatePreview = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/generatePreview/v2',
    data,
    Istoast,
    Send
  )
}

export const generatePreviewV3 = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/generatePreview/v3',
    data,
    Istoast,
    Send
  )
}
// 获取预览图片生成进度
export const generatePreviewStatus = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/generatePreviewStatus',
    data,
    Istoast,
    Send
  )
}
// 定制产品分页
export const productPage = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/custom/product/page', data, Istoast, Send)
}
// 删除定制产品
export const delProduct = (data, Istoast = true, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/remove',
    data,
    Istoast,
    Send
  )
}
// 批量删除定制产品
export const batchDelProduct = (data, Istoast = true, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/remove/batch',
    data,
    Istoast,
    Send
  )
}
// 产品统计
export const productCount = (data, Istoast = true, Send = false) => {
  return apiAxios('post', '/shopapi/custom/product/count', data, Istoast, Send)
}
// 在shopify那边隐藏
export const hideOnShopify = (data, Istoast = true, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/hideOnShopify',
    data,
    Istoast,
    Send
  )
}
// 在shopify那边显示
export const showOnShopify = (data, Istoast = true, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/showOnShopify',
    data,
    Istoast,
    Send
  )
}

// 定制页详情
export const customDetail = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/detail',
    data,
    Istoast,
    Send
  )
}
// 定制页详情
export const productExport = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/export',
    data,
    Istoast,
    Send
  )
}
// 全部复制产品
export const copyProduct = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/all/batch',
    data,
    Istoast,
    Send
  )
}
// 刀版切图
export const cutImage = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/cutImage',
    data,
    Istoast,
    Send
  )
}
// 实时预览刀版主图
export const generateKnifePreview = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/realtimePreview',
    data,
    Istoast,
    Send
  )
}

export const generateKnifePreviewV2 = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/realtimePreview/v2',
    data,
    Istoast,
    Send
  )
}
// 批量复制产品
export const productCopy = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/copy/batch',
    data,
    Istoast,
    Send
  )
}
// 颜色列表
export const colorList = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/blankProduct/color/list',
    data,
    Istoast,
    Send
  )
}
// sku更新
export const skuModify = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/sku/modify',
    data,
    Istoast,
    Send
  )
}

// 添加diy定制产品
export const addDiyDesignProduct = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/diy_design/add',
    data,
    Istoast,
    Send,
    false,
    { 'Content-Type': 'application/json;charset=UTF-8' },
    true
  )
}

// 批量上传定制产品
export const batchUploadCustomProduct = (
  data,
  Istoast = false,
  Send = false
) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/upload/batch',
    data,
    Istoast,
    Send,
    false,
    { 'Content-Type': 'application/json;charset=UTF-8' },
    true
  )
}

// 上传的定制产品详情
export const uploadCustomProductDetail = (
  data,
  Istoast = false,
  Send = false
) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/upload/detail',
    data,
    Istoast,
    Send
  )
}

// 修改上传的定制产品
export const editUploadCustomProduct = (
  data,
  Istoast = false,
  Send = false
) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/upload/edit',
    data,
    Istoast,
    Send,
    false,
    { 'Content-Type': 'application/json;charset=UTF-8' },
    true
  )
}
// 删除配件
export const fittingsRemove = (
  data,
  Istoast = false,
  Send = false
) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/fittings/remove',
    data,
    Istoast,
    Send,
    false,
    { 'Content-Type': 'application/json;charset=UTF-8' },
    true
  )
}
// 提交配件定制信息
export const fittingsSubmit = (
  data,
  Istoast = false,
  Send = false
) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/fittings/submit',
    data,
    Istoast,
    Send,
    false,
    { 'Content-Type': 'application/json;charset=UTF-8' },
    true
  )
}
// 获取定制产品生成进度
export const fittingGenerateStatus = (
  data,
  Istoast = false,
  Send = false
) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/generateStatus',
    data,
    Istoast,
    Send
  )
}
// 批量删除产品
export const productRemoveBatch = (
  data,
  Istoast = false,
  Send = false
) => {
  return apiAxios(
    'post',
    '/shopapi/custom/product/remove/batch',
    data,
    Istoast,
    Send
  )
}
