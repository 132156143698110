<template>
  <header class="bgwhite userSelectNone positionFixed w-100 top0 header">
    <div class="headerContainer d-flex a-center j-sb ignore_view">
      <div class="d-flex a-center">
        <div
          v-if="computedSpan>=12"
          class="menuBtn d-flex a-center j-center cursorPointer commontransition primaryHover"
          @click="visible=true"
        >
          <a-icon class="font25" type="unordered-list" />
        </div>
        <div class="d-flex a-center">
          <logo />
        </div>
        <div v-if="computedSpan<12" class="d-flex a-center ml40">
          <div v-for="(urlItem) in linkList" :key="urlItem.title" class="cursorPointer mr10">
            <template v-if="urlItem.path">
              <template v-if="urlItem.title === 'Custom Gift'">
                <a-dropdown>
                  <a
                    :class="`linkIntem font18 ${$route.name==='Diy'?'linkIntemActive':''}`"
                    @click="callNewPage(urlItem.path)"
                  >{{ urlItem.title }}</a>
                  <!--                  <r-link
                    class="linkIntem font18"
                    active-class="linkIntemActive"
                    :exact="true"
                    :to="urlItem.path"
                  >{{ urlItem.title }}</r-link>-->
                  <a-menu
                    slot="overlay"
                    class="userSelectNone"
                    style="transform: none;min-width: 10.5vw;
                    animation: menu-sub-dropdown-animation-fade-in, menu-sub-dropdown-animation-move-up !important;
                    animation-duration: 0.3s, 0.3s !important;"
                    :selected-keys="selectProductTypeId?[selectProductTypeId]:[-1]"
                    :default-selected-keys="[-1]"
                    @click="handleProductTypeChange"
                  >
                    <template v-for="topProductType in productTypes">
                      <a-sub-menu
                        v-if="topProductType.children"
                        :key="topProductType.id"
                      >
                        <span slot="title" :title="topProductType.name">
                          <span>{{ topProductType.name }}</span>
                        </span>
                        <a-menu-item
                          v-for="subProductType in topProductType.children"
                          :key="subProductType.id"
                          style="min-width: 9vw"
                        >
                          {{ subProductType.name }}
                        </a-menu-item>
                      </a-sub-menu>
                      <a-menu-item
                        v-else
                        :key="topProductType.id"
                        :title="topProductType.name"
                      >
                        {{ topProductType.name }}
                      </a-menu-item>
                    </template>
                  </a-menu>
                </a-dropdown>
              </template>
              <template v-else>
                <r-link
                  class="linkIntem font18"
                  active-class="linkIntemActive"
                  :exact="true"
                  :to="urlItem.path"
                >{{ urlItem.title }}</r-link>
              </template>
            </template>
            <template v-else>
              <a target="_blank" :href="urlItem.href" class="linkIntem font18">
                {{ urlItem.title }}
              </a>
            </template>
          </div>
        </div>
      </div>
      <div class="d-flex a-center">
        <span class="positionRe">
          <a-popover placement="bottom" trigger="click" @visibleChange="showSearchDiv">
            <template slot="content">
              <div
                class="searchDiv"
                style="width: 21vw;"
              >
                <!-- height: 3.125vw; -->
                <div class="d-flex a-center w-100">
                  <a-icon type="search" class="mr10 font25" style="color: #999999;" />
                  <a-select
                    ref="searchInput"
                    v-model="searchInput"
                    :auto-focus="true"
                    show-search
                    :default-active-first-option="true"
                    class="searchInput"
                    placeholder="search..."
                    style="width: 100%"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="doSearchProduct"
                    @change="handleSearchProductChange"
                  >
                    <!-- <a-select-option v-for="d in products" :key="d.id">
                    <img class="searchImg mr5" :src="d.mainShowImageUrl"/>
                    <span class="fontBold">{{ d.model }}</span>
                  </a-select-option> -->
                    <div slot="notFoundContent" style="display: none;" />
                  </a-select>
                </div>
                <div v-if="searching" class="d-flex j-center a-center pt20"><a-spin slot="notFoundContent" size="small" /></div>
                <div v-if="products.length && !searching">
                  <div class="mt10 mb10" style="border-top: 1px solid rgb(235,238,240);" />
                  <div class="searchList">
                    <div v-for="d in products" :key="d.id" class="searchItem mb10 d-flex a-center cursorPointer primaryHover commonHover" @click="handleSearchProductChange(d.id)">
                      <img class="searchImg mr5" :src="d.mainShowImageUrl">
                      <span class="fontBold textEllipsisone">{{ d.model }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <a-icon type="search" class=" font24 plr10 cursorPointer primaryHover searchicon" />
          </a-popover>

        </span>
        <span v-if="MerchantInfo.login" @click="goShoppingCart">
          <img class="toolIcon cursorPointer ml20 mr20" src="@/assets/scIcon.png">
        </span>
        <div v-if="MerchantInfo.login" class="plr10 d-flex a-center cursorPointer">
          <a-dropdown :trigger="['click']">
            <div class="account-msg">
              <img
                v-show="CurrentShop.shopType === enums.shopTypeEnum.SHOPIFY.value"
                src="../assets/img/shopify.svg"
              >
              <img
                v-show="CurrentShop.shopType === enums.shopTypeEnum.GENERAL.value"
                src="../assets/img/retail-store.svg"
              >
              <img
                v-show="CurrentShop.shopType === enums.shopTypeEnum.WOOCOMMERCE.value"
                src="../assets/img/woocommerce.svg"
              >
              <img
                v-show="CurrentShop.shopType === enums.shopTypeEnum.ETSY.value"
                src="../assets/img/etsy.png"
              >
              <img
                v-show="CurrentShop.shopType === enums.shopTypeEnum.NOT_BIND.value"
                src="../assets/img/weilianjie.svg"
              >
              <span style="margin-left: 0.18vw;">{{ MerchantInfo.currentShop.name }}</span>
              <a-icon style="margin-left: 0.18vw;" type="caret-down" class="commontransition triggericon" />
            </div>
            <a-menu slot="overlay">
              <a-menu-item
                v-for="(item, index) in storeList"
                :key="index"
                @click="onShopChange(item.shopId)"
              >
                <div class="store-msg">
                  <img v-if="item.type === enums.shopTypeEnum.SHOPIFY.value" src="../assets/img/shopify.svg">
                  <img
                    v-if="item.type === enums.shopTypeEnum.GENERAL.value"
                    src="../assets/img/retail-store.svg"
                  >
                  <img
                    v-if="item.type === enums.shopTypeEnum.WOOCOMMERCE.value"
                    src="../assets/img/woocommerce.svg"
                  >
                  <img
                    v-if="item.type === enums.shopTypeEnum.ETSY.value"
                    src="../assets/img/etsy.png"
                  >
                  <img v-if="item.type === enums.shopTypeEnum.NOT_BIND.value" src="../assets/img/weilianjie.svg">
                  <div class="store-right">
                    <a>{{ item.name }}</a>
                    <p>{{ item.isConnect ? "Connected" : "Not Connected" }}</p>
                  </div>
                </div>
              </a-menu-item>
              <a-menu-item
                class="manage"
                @click="toShopList"
              >
                <a-icon type="setting" class="set" theme="filled" />
                <span>Manage My Stores</span>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <a v-if="!MerchantInfo.login" type="button" class="sign-btn" @click="callNewPage('/login')">
          <a-icon type="user" />
          Log in
        </a>
        <a v-if="!MerchantInfo.login" type="button" class="sign-btn" @click="callNewPage('/register')">
          <a-icon type="user-add" />
          Sign up
        </a>
        <div v-if="MerchantInfo.login" class="plr10 d-flex a-center cursorPointer">
          <a-dropdown :trigger="['click']">
            <div class="account-logo">
              <img src="../assets/img/undraw_profile_pic_ic5t.svg">
            </div>
            <div slot="overlay" class="mask-account">
              <div class="mask-account-user text-ellipsis">{{ MerchantInfo.username }}</div>
              <router-link to="/account?type=0">
                <p class="mask-account-list">My Account</p>
              </router-link>
              <router-link to="/account?type=1">
                <p class="mask-account-list">Payment</p>
              </router-link>
              <router-link to="/account?type=2">
                <p class="mask-account-list">My Files</p>
              </router-link>
              <router-link to="/account?type=4">
                <p class="mask-account-list">My Stores</p>
              </router-link>
              <router-link to="/account?type=3">
                <p class="mask-account-list">Settings</p>
              </router-link>
              <div
                v-if="MerchantInfo.currentShop.shopType === enums.shopTypeEnum.GENERAL.value"
                class="mask-account-logout"
                @click="signOut"
              >
                Sign out
              </div>
            </div>
          </a-dropdown>
        </div>
      </div>
    </div>
    <a-drawer placement="left" :closable="false" :visible="visible" @close="visible=false">
      <div>
        <div v-for="(urlItem) in linkList" :key="urlItem.title" class="cursorPointer mb20">
          <template v-if="urlItem.path">
            <r-link
              class="linkIntem font18"
              active-class="linkIntemActive"
              :exact="true"
              :to="urlItem.path"
            >{{ urlItem.title }}</r-link>
          </template>
          <template v-else>
            <a target="_blank" :href="urlItem.href" class="linkIntem font18">
              {{ urlItem.title }}
            </a>
          </template>
        </div>
      </div>
    </a-drawer>
  </header>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { shopList } from '@/api/woocom'
import { changeShop } from '@/api/merchant'
import shopTypeEnum from '@/enums/shopTypeEnum'
import { logout } from '@/api/login'
import { blankProductPage, typeTree } from '@/api/product'
import blankProductTypeCategoryEnum from '@/enums/blank_product/blankProductTypeCategoryEnum.js'
import blankProductTypeEnum from '@/enums/blank_product/blankProductTypeEnum.js'
export default {
  inject: ['reload'],
  data() {
    return {
      selectProductTypeId: null,
      productTypes: [],
      visible: false,
      storeList: [],
      enums: {
        shopTypeEnum
      },
      searching: false,
      products: [],
      searchInput: ''
    }
  },
  computed: {
    ...mapGetters('ConfigModule', ['computedSpan']),
    ...mapState({
      CurrentShop: state => state.User.merchantInfo.currentShop,
      MerchantInfo: state => state.User.merchantInfo
    }),
    linkList() {
      var dashboard = {
        title: 'Dashboard',
        path: '/'
      }
      var catalog = {
        title: 'Catalog',
        path: '/catalog'
      }
      var diy = {
        title: 'Custom Gift',
        path: '/custom-gift'
      }
      var myProduct = {
        title: 'My Products',
        path: '/my-product'
      }
      var orders = {
        title: 'Orders',
        path: '/orderlist'
      }
      /*  var guide = {
        title: 'Guide',
        href: 'https://jetprintapp.com/how-it-works/'
      }*/
      /* var faq = {
        title: 'FAQ',
        href: 'https://www.jetprintapp.com/faq/'
      }*/
      return [dashboard, catalog, diy, myProduct, orders]
    }
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        var typeId = this.$route.query.type
        if (typeId) {
          this.selectProductTypeId = parseInt(typeId)
        } else {
          this.selectProductTypeId = null
        }
        // this.loadProducts()
      }
    }
  },
  mounted() {
    this.getShopList()
    this.loadProductTypes()
  },
  methods: {
    ...mapMutations('User', [
      'merchantInfoMutations',
      'loginMsgmutations',
      'merchantShopMutations',
      'IsPullProductMutations',
      'Tokenmutations'
    ]),
    async loadProductTypes() {
      const { data } = await typeTree({
        blankProductTypeCategory: blankProductTypeCategoryEnum.DIY.value
      })
      this.productTypes = data
    },
    handleProductTypeChange(e) {
      if (e.key === -1) {
        this.selectProductTypeId = null
      } else {
        this.selectProductTypeId = e.key
      }
      if (this.selectProductTypeId) {
        this.callNewPage(`/custom-gift?type=` + this.selectProductTypeId)
      } else {
        this.callNewPage(`/custom-gift`)
      }
      // this.reloadProducts()
    },
    async getShopList() {
      const { data } = await shopList()
      this.storeList = data.records || []
    },
    showSearchDiv(v) {
      if (v) {
        this.searchInput = ''
        this.products = []
      }
    },

    go() {
      window.open('https://www.jetprintapp.com/faq/')
    },
    goShoppingCart() {
      this.callNewPage(`/cart`)
    },
    toShopList() {
      this.callNewPage({
        path: '/account',
        query: { type: 4 }
      })
    },
    async onShopChange(shopId) {
      const { code } = await changeShop({ shopId: shopId })
      if (code === 200) {
        const storeList = this.storeList
        for (let i = 0; i < storeList.length; i++) {
          if (shopId === storeList[i].shopId) {
            const param = {
              name: storeList[i].name,
              shopId: storeList[i].shopId,
              shopType: storeList[i].type
            }
            // this.callNewPage("/");
            this.reload()
            this.merchantShopMutations(param)
            this.IsPullProductMutations(true)
            break
          }
        }
      }
    },
    async doSearchProduct(value) {
      if (!value) {
        this.products = []
        return
      }
      this.searching = true

      try {
        var postData = {}
        postData.current = 1
        postData.size = 10
        postData.keyword = value
        postData.types = [blankProductTypeEnum.JET.value, blankProductTypeEnum.PRINTIFY.value, blankProductTypeEnum.SDS.value, blankProductTypeEnum.DIY.value]
        var response = await blankProductPage(postData)
        if (response.code === 200) {
          this.products = response.data.records || []
        }
      } finally {
        this.searching = false
      }
    },
    handleSearchProductChange(id) {
      if (id) {
        var product = this.getProductById(id)
        this.callNewPage(`/product/${product.type}/${id}`)
      }
    },
    getProductById(id) {
      for (const product of this.products) {
        if (product.id === id) {
          return product
        }
      }
      return null
    },
    // 退出登录
    async signOut() {
      const { code } = await logout()
      if (code === 200) {
        this.loginMsgmutations('')
        this.Tokenmutations(null)
        this.merchantInfoMutations({ login: false })
        this.callNewPage('/')
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/less/theme.less";
.searchImg{
    width: 35px;
    height: 35px;
    border-radius: 5px;
  }
  .searchItem{
        height: 40px;
  }
  .searchList{
    max-height: 30vh;
    overflow: hidden;
    overflow-y: auto;
    .commonBar(#d2d2d8, 5px, 8px);
  }
.header {
  z-index: 9;
  .ant-dropdown-open{
      .triggericon{
        transform: rotate(180deg);
      }
  }
  .searchicon{
    &.ant-popover-open{
      color: @primaryColor !important;
    }
  }
  .menuBtn {
    height: 80px;
    width: 80px;
  }

  .headerContainer {
    width: @pageWidth;
    margin: 0 auto;
    height: 80px;
    .searchDiv{
      width: 375px;
      padding: 20px;
      left: -50px;
      background-color: #ffffff;
      border-radius: 12px;
      box-shadow: 0px 0px 50px 0px rgba(82,63,105,0.15);
      animation-duration: 0.3s, 0.3s;
      animation-timing-function: ease, ease;
      animation-delay: 0s, 0s;
      animation-iteration-count: 1, 1;
      animation-direction: normal, normal;
      animation-fill-mode: none, none;
      animation-play-state: running, running;
      animation-name: menu-sub-dropdown-animation-fade-in, menu-sub-dropdown-animation-move-up;
      .searchInput{
        border:none;
        &:focus{
          border: none;
          box-shadow: none;
        }
      }
    }
    .icon {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
    .toolIcon {
      width: 24px;
      height: 24px;
    }
  }
}
.linkIntem {
  color: rgba(16, 16, 16, 100);
  font-family: SourceHanSansSC-regular;
  transition: all 0.1s ease-in;
  padding: 5px 14px;
  height: 40px;
  line-height: 40px;
  border-radius: 10px;
  &:hover {
    color: rgba(0, 158, 247, 100);
    background-color: rgba(242, 246, 255, 100);
  }
  &.linkIntemActive {
    color: rgba(0, 158, 247, 100);
    background-color: rgba(242, 246, 255, 100);
  }
}

header .sign-btn {
  margin-right: 10px;
  font-size: 15px;
  padding: 5px;
  color: rgba(56, 56, 56, 0.6);
}

header .account-msg {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.account-msg img{
  width: 30px;
  height: 30px;
}

.store-msg {
  display: flex;
  align-items: center;
  min-width: 180px;
}
.store-msg img {
  width: 30px;
  height: 30px;
}
.store-msg .store-right {
  margin-left: 15px;
}

.store-msg .store-right a {
  color: #333;
  font-weight: bold;
}
.store-msg .store-right p {
  font-size: 13px;
  margin: 0;
  padding: 0;
}
.account-logo img {
  cursor: pointer;
  margin-left: 24px;
  width: 40px;
  height: 40px;
}
.mask-account {
  position: absolute;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25), 0 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  color: #1b1b1b;
  min-width: 217px;
  top: 90%;
  right: 0;
  z-index: 2;
  animation: fadeTopToBottom 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.mask-account-user {
  font-size: 15px;
  line-height: 18px;
  color: rgba(56, 56, 56, 0.5);
  text-align: left;
  padding: 14px 20px;
  margin-bottom: 6px;
  border-bottom: 1px solid rgba(56, 56, 56, 0.05);
}
.mask-account-list {
  padding: 12px 20px;
  text-align: left;
  font-size: 15px;
  transition: all 0.2s ease;
  color: #383838;
  position: relative;
  cursor: pointer;
}
.mask-account-logout {
  cursor: pointer;
  border-top: 1px solid rgba(56, 56, 56, 0.05);
  margin-top: 12px;
  margin-bottom: 6px;
  padding: 12px 20px;
  text-align: left;
  font-size: 15px;
  transition: all 0.2s ease;
  color: #383838;
  position: relative;
}
.header .mask-account-list:hover {
  background-color: #f5f5f5;
}
.header .mask-account-logout:hover {
  background-color: #f5f5f5;
}
.ant-dropdown-menu-sub > .ant-dropdown-menu-item:hover{
  color: #009ef7 !important;
}
.ant-dropdown-menu-sub > .ant-dropdown-menu-item-active{
  background-color: transparent;
  color: #009ef7 !important;
}
.ant-dropdown-menu-item-selected{
  // background-color: #e6f7ff !important;
  color: rgba(0, 158, 247, 100) !important;
  background-color: rgba(242, 246, 255, 100) !important;
  border-radius: 8px;
}
.ant-dropdown-menu-item-active.ant-dropdown-menu-item-selected{
  // background-color: #e6f7ff;
  color: rgba(0, 158, 247, 100) !important;
  background-color: rgba(242, 246, 255, 100) !important;
  border-radius: 8px;
}
// 首页diy第一级弹框里选项文字hover样式start---
.ant-dropdown-menu-item:hover{
  background-color: transparent;
  color: #009ef7;
}

/deep/ .ant-dropdown-menu-submenu-title:hover{
  background-color: transparent !important;
  color: #009ef7 !important;
}
// 首页diy第一级弹框里选项文字hover样式end---
.ant-dropdown-menu-item:hover > .ant-checkbox-wrapper{
  background-color: transparent;
  color: #009ef7 !important;
}
.ant-dropdown-menu-item-active > .ant-checkbox-wrapper{
  background-color: transparent;
  color: #009ef7 !important;
}
// 首页diy第一级弹框内边距
.ant-dropdown-menu{
  padding: 10px !important;
}
</style>
