<template>
  <div style="cursor: pointer" @click="toMainPage">
    <img v-if="appName === 'Jetprint'" src="@/assets/img/logo.svg">
    <img
      v-if="appName === 'Doge'"
      src="@/assets/img/doge_logo.png"
      @click="toMainPage"
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Logo',
  computed: {
    ...mapGetters('User', {
      appName: 'getAppName',
      mainPage: 'getMainPage'
    })
  },
  methods: {
    toMainPage() {
      location.href = this.mainPage
    }
  }
}
</script>

<style scoped>
img {
  height: 50px;
}
</style>
