<template>
  <div
    v-if="show"
    :style="'background-color:' + item.componentColor"
    class="tipsContainer  d-flex a-center j-sb mb15 "
  >
    <img
      class="icon mr20"
      :src="!item.iconUrl
        ? require('../assets/img/alert-white.svg')
        : item.iconUrl"
      fit="cover"
    >
    <div :style="'color:' + item.fontColor" class="flex-1" v-html="item.title" />
    <a-icon type="close" class="font24 cursorPointer commontransition primaryHover" @click="show = false" />
  </div>
</template>

<script>
export default {
  name: 'Warn1',
  props: {
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      show: true
    }
  },
  methods: {
    tranformTitle(title) {
      const reg = /<\/?.+?\/?>/g
      return title.replace(reg, '')
    }
  }
}
</script>

<style lang="less" scoped>
.tipsContainer {
  border-radius: 12px;
  padding: 20px 15px 20px 20px;
  .icon {
    width: 36px;
    height: 36px;
    object-fit: cover;
  }
}
</style>
