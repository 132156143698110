import { apiAxios } from '../utils/request.js'
// 商户登录
export const Login = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/merchant/login', data, Istoast, Send)
}
export const Register = (data, Istoast = false, Send = false) => {
  return apiAxios('post', 'shopapi/merchant/register', data, Istoast, Send)
}
// 修改密码
export const UpdatePwd = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/merchant/updatePasswd',
    data,
    Istoast,
    Send
  )
}
// 修改邮箱
export const emailChange = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/merchant/email/change',
    data,
    Istoast,
    Send
  )
}
// 忘记密码
export const ForgetPasswd = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/merchant/forgetPasswd',
    data,
    Istoast,
    Send
  )
}
// 忘记密码-重置密码
export const forgetPasswdReset = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/merchant/forgetPasswdReset',
    data,
    Istoast,
    Send
  )
}
// 邮箱确认
export const emailConfirm = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/merchant/email/confirm',
    data,
    Istoast,
    Send
  )
}
// 发送邮箱确认邮件
export const confirmSend = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/merchant/email/confirm/send',
    data,
    Istoast,
    Send
  )
}
