import { apiAxios } from '../utils/request.js'
// 商户信息
export const merchantInfo = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/merchant/info', data, Istoast, Send)
}
// 修改商户信息
export const editMerchantInfo = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/merchant/info/edit', data, Istoast, Send)
}
// 获取当前商户信息(登录状态，基本商户信息)
export const currentMerchant = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/merchant/current', data, Istoast, Send)
}
// 解绑信用卡
export const unbindCard = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/merchant/payment/card/unbind',
    data,
    Istoast,
    Send
  )
}
// 切换店铺
export const changeShop = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/shop/change', data, Istoast, Send)
}
// 首页
export const statisticsIndex = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/merchant/index/statistics',
    data,
    Istoast,
    Send
  )
}
