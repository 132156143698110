<template>
  <div class="">
    <a-modal
      v-model="visible"
      top="5vh"
      wrap-class-name="noticeModalContainer"
      centered
      :width="computedWidth"
    >
      <div v-if="currentNotice" class="content">
        <div class="d-flex j-center text-center">
          <span class="color333 font16 fontBold" v-html="currentNotice.title" />
        </div>
        <div class="d-flex j-center a-center mt15 mb40">
          <span
            class="colorgraylightColor font14"
          >Release time:{{ currentNotice.addDate }}</span>
        </div>
        <div class="textHtml" v-html="currentNotice.content" />
      </div>
      <span slot="footer" class="dialog-footer d-flex j-center positionRe">
        <a-button
          class="btn"
          type="default"
          :disabled="currentIndex <= 0"
          @click="previous"
        >
          Previous
        </a-button>
        <a-button
          type="primary"
          class="btn"
          :disabled="currentIndex>=noticeList.length - 1"
          @click="next"
        >
          Next
        </a-button>
        <div class="d-flex a-center positionAb pageInfo">
          {{ currentIndex + 1 }} / {{ noticeList.length }}
        </div>
      </span>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 780
    },
    noticeList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      visible: false,
      currentIndex: 0
    }
  },
  computed: {
    computedWidth() {
      return `${this.width / 16}vw`
    },
    currentNotice() {
      if (!this.noticeList) {
        return null
      }
      return this.noticeList[this.currentIndex]
    }
  },
  methods: {
    show() {
      if (!this.noticeList) {
        return
      }
      this.currentIndex = 0
      this.visible = true
    },
    next() {
      if (this.currentIndex < this.noticeList.length - 1) {
        this.currentIndex = this.currentIndex + 1
      }
    },
    previous() {
      if (this.currentIndex > 0) {
        this.currentIndex = this.currentIndex - 1
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../assets/less/theme.less";
.noticeModalContainer {
    /deep/ .el-dialog__wrapper {
      .commonBar(#d2d2d8, 8px, 8px);
    }
    /deep/ .el-dialog--center .el-dialog__body {
      padding: 1.5625vw 0vw 1.875vw;
    }
    .pageInfo{
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  .btn {
    width: 108px;
    height: 44px;
  }
  .content {
    .textHtml {
      height: 480px;
      overflow: hidden;
      overflow-y: auto;
      padding: 0 1.5625vw;
      .commonBar(#d2d2d8, 6px, 8px);
    }
  }
}
</style>
