/**
 * 移除节点
 * @param parent
 * @param childs
 */
import { VUE_META_ATTRIBUTE } from './constants'

// eslint-disable-next-line no-underscore-dangle
export default function _removeNode(parent) {
  const childs = parent.querySelectorAll(`[${VUE_META_ATTRIBUTE}]`)
  // eslint-disable-next-line no-plusplus
  for (let i = childs.length - 1; i > -1; i--) {
    if (childs[i].getAttribute(VUE_META_ATTRIBUTE) === 'true') {
      parent.removeChild(childs[i])
    }
  }
}
